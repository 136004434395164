import { add } from '@client/api/wishlists'

const start = ({ id, productId }) => {
  return {
    type: 'ADD_TO_WISHLIST_START',
    payload: { id, productId }
  }
}

const success = ({ id, productId, wishlist }) => {
  return {
    type: 'ADD_TO_WISHLIST_SUCCESS',
    payload: { id, productId, wishlist }
  }
}

const error = ({ id, productId, response }) => {
  return {
    type: 'ADD_TO_WISHLIST_ERROR',
    payload: { id, productId, response }
  }
}

const addToWishlist = ({ id, productId }) => {
  return (dispatch) => {
    dispatch(start({ id, productId }))

    return add({ id, productId })
      .then(
        (response) => { dispatch(success({ id, productId, wishlist: response.data.wishlist })) },
        (errorData) => { dispatch(error({ id, productId, response: errorData.response })) }
      )
  }
}

export default addToWishlist
