// React
import React, { useEffect, useRef } from 'react'

// Utils
import { t } from '@client/i18n/localize'

// Components
import Intro from '@client/react/components/Picker/Intro'

// Libraries
import { useDispatch, useSelector } from 'react-redux'

// Shared
import { updateCart } from '@client/redux/actions/cart'
import { openPicker, closePicker } from '@client/redux/actions/picker'

const ModalWrapper = ({ errors, values, children, intro }) => {
  const dispatch = useDispatch()

  const modalVisible = useSelector(state => state.picker.open)

  const backgroundNode = useRef(null)

  useEffect(() => {
    const html = document.querySelector('html')

    if (modalVisible) {
      html.classList.add('no-overflow')
    } else {
      html.classList.remove('no-overflow')
    }
  }, [modalVisible])

  const handleModalVisible = () => {
    if (modalVisible) {
      dispatch(closePicker())
    } else {
      dispatch(openPicker())
    }
  }

  const handleApplyPress = () => {
    if (!errors.length) {
      dispatch(updateCart({
        pickup_time: values.pickupTime,
        return_time: values.returnTime,
        pickup_depot_id: values.pickupDepot.id,
        return_depot_id: values.returnDepot.id
      }))

      handleModalVisible()
    }
  }

  const handleClick = (e) => {
    if (e.target === backgroundNode.current) {
      handleModalVisible()
    }
  }

  const isDisabled = () => {
    if (!values || errors.length) return true

    for (const value in values) {
      if (values[value] === null || values[value] === undefined) return true
    }

    return false
  }

  return (
    <>
      {intro && <Intro values={values} onClick={handleModalVisible} errors={errors} />}

      {modalVisible && (
        <div id="picker">
          <div className="bc-popover" onClick={handleClick} ref={backgroundNode}>
            <div className="bc-popover-box">
              <div className="bc-popover-content">
                <div className="picker">
                  <div className="picker__header">
                    <h2 className="picker__title">{t('cart_picker.choose_period_short')}</h2>
                    <i className="fas fa-times picker__close-icon" onClick={handleModalVisible}></i>
                  </div>
                  {children}
                  {errors.length !== 0 && errors.map((error, index) => <p key={index} className="picker__error">{error.message}</p>)}
                  <div className="picker__footer gap-2">
                    <button type="button" className="btn btn-link" onClick={handleModalVisible}>
                      {t('cancel')}
                    </button>
                    <button type="button" onClick={handleApplyPress} disabled={isDisabled()} className="btn btn-primary">{t('save')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModalWrapper
