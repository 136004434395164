import React from 'react'

const OptionalAccessoryProduct = ({ accessories }) => {
  const products = [...accessories.reduce((acc, accessory) => {
    const { product, qty } = accessory
    const item = acc.get(product.id) || Object.assign({}, product, { qty: 0 })

    item.qty += qty

    return acc.set(product.id, item)
  }, new Map).values()]

  return (
    <ul className="normal mb-0 text-small">
      {products.map((product) => (
        <li key={product.id}>
          {product.qty}x {product.name}
        </li>
      ))}
    </ul>
  )
}

export default OptionalAccessoryProduct
