import axios from '@client/lib/axios'

export const fetchAll = (params) => {
  return axios({
    method: 'get',
    url: '/api/2/depots'
  })
}

export const fetch = ({ id }) => {
  return axios({
    method: 'get',
    url: `/api/2/depots/${id}`
  })
}
