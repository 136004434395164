// React
import React, { useState, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

// Components
import Calendar from './Picker/Calendar'

// Libraries
import { usePopper } from 'react-popper'

// Shared
import { date } from '@client/i18n/localize'
import getPopoverPosition from '@client/utils/getPopoverPosition'

const PopoverContainer = ({ style, forwardRef, children, ...props }) => (
  <div className="bc-date-picker-popover" style={style} ref={forwardRef} {...props}>
    {children}
  </div>
)

const Popover = (props) => {
  const useOutsideClick = (ref) => {
    useEffect(() => {
      const handleOutsideClick = (e) => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
          if (props.parentRef && props.parentRef.current && props.parentRef.current.contains(e.target)) return

          props.handleOutsideClick()
        }
      }

      document.addEventListener('mousedown', handleOutsideClick)

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick)
      }
    }, [ref])
  }

  const ref = useRef(null)
  useOutsideClick(ref)

  const selectedDay = (type, range) => {
    const date = {
      "pickup": range.from,
      "return": range.to || range.from
    }

    return date[type]
  }

  return (
    <div style={props.style} ref={ref}>
      <Calendar
        onChange={props.onChange}
        isDayAvailable={props.isDayAvailable}
        firstAvailableAt={props.firstAvailableAt}
        range={props.range}
        type={props.type}
        selectedDay={selectedDay(props.type, props.range)}
      />
    </div>
  )
}

const DatePicker = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, -5],
        },
      }
    ],
  })

  useEffect(() => {
    if (ref && ref.current) {
      setPopoverPosition(getPopoverPosition(ref.current, props.popoverPosition, true))
    }
  }, [ref])

  const ref = useRef(null)

  const handleOutsideClick = (e) => {
    e && e.preventDefault()
    setPopoverOpen(false)
  }

  const handleDateChange = (value) => {
    props.onChange(value)
    setPopoverOpen(false)
  }

  const hasError = (type, data) => {
    if (props.errors.length) {
      return props.errors.find((error) => error.error.data === data && error.error.type === type) !== undefined
    }

    return false
  }

  return (
    <>
      <div
        id={props.id}
        ref={setReferenceElement}
        className={`bc-date-picker ${props.disabled ? 'bc-date-picker--disabled' : ''} ${hasError(props.type, 'date') ? 'bc-date-picker--has-error' : ''}`}
        onClick={() => {
          if (!props.disabled) {
            setPopoverOpen(!popoverOpen)
          }
        }}
      >
        <div className="bc-date-picker__value-container">
          {props.value ? (
            <div className="bc-date-picker__value">
              <i className={`text-muted fas fa-${props.icon}`} />
              <span className="ml-2">{date(props.value)}</span>
            </div>
          ) : (
            <div className="bc-date-picker__placeholder">{props.placeholder}</div>
          )}
        </div>
        {!props.disabled && (
          <div className="bc-date-picker__indicator-container">
            <i className={`fas ${popoverOpen ? 'fa-sort-up' : 'fa-sort-down'} bc-date-picker__indicator`}></i>
          </div>
        )}
      </div>
      {popoverOpen &&
        createPortal(
          <PopoverContainer
            forwardRef={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <Popover
              handleOutsideClick={handleOutsideClick}
              onChange={handleDateChange}
              errors={props.errors}
              isDayAvailable={props.isDayAvailable}
              firstAvailableAt={props.firstAvailableAt}
              range={props.range}
              type={props.type}
              parentRef={ref}
            />
          </PopoverContainer>,
          document.body
        )}
    </>
  )
}

export default DatePicker
