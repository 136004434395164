import { clear } from '@client/api/cart'

const start = ({ id, type }) => {
  return {
    type: 'CART_CLEAR_START',
    payload: { id, type }
  }
}

const success = ({ cart }) => {
  return {
    type: 'CART_CLEAR_SUCCESS',
    payload: { cart }
  }
}

const error = ({ response }) => {
  return {
    type: 'CART_CLEAR_ERROR',
    payload: { response }
  }
}

const clearCart = () => {
  return (dispatch, getState) => {
    const { cart } = getState()

    dispatch(start({ token: cart.token }))

    return clear({ token: cart.token })
      .then(
        (response) => { dispatch(success(response.data)) },
        (errorData) => { dispatch(error({ token: cart.token, response: errorData.response })) }
      )
  }
}

export default clearCart
