import React, { useState } from 'react'

// Libraries
import find from 'lodash/find'

// Utils
import { t } from '@client/i18n/localize'
import queryParams from '@client/utils/queryParams'

// Components
import Select from './Select'

const ProductSort = ({ sort, direction, currentParams }) => {
  const sortOptions = [
    {
      value: '',
      label: t('product_results.sort.popularity')
    },
    {
      value: 'price_in_cents asc',
      label: t('product_results.sort.price_in_cents_asc')
    },
    {
      value: 'price_in_cents desc',
      label: t('product_results.sort.price_in_cents_desc')
    }
  ]
  const selectedOption = find(sortOptions, (option) =>
    option.value === `${sort} ${direction}`
  )

  const [value, setValue] = useState(selectedOption || sortOptions[0])

  const onChange = (option) => {
    setValue(option)

    const [newSort, newDirection] = option.value.split(' ')
    const path = window.location.pathname
    const params = { ...currentParams }

    if (newSort !== '') {
      params.sort = newSort
      params.direction = newDirection
    }

    Turbolinks.visit(`${path}?${queryParams(params)}`)
  }

  return (
    <div className="hstack gap-2 justify-content-between">
      <span className="text-medium">{t('product_results.sort.sort_by')}</span>
      <Select
        options={sortOptions}
        defaultValue={sortOptions[0]}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default ProductSort
