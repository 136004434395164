import { fetchAll } from '@client/api/depots'

const start = () => {
  return {
    type: 'FETCH_DEPOTS_START'
  }
}

const success = ({ depots }) => {
  return {
    type: 'FETCH_DEPOTS_SUCCESS',
    payload: { depots }
  }
}

const error = ({ response }) => {
  return {
    type: 'FETCH_DEPOTS_ERROR',
    payload: { response }
  }
}

const fetchDepots = () => {
  return (dispatch) => {
    dispatch(start())

    return fetchAll()
      .then(
        (response) => { dispatch(success({ depots: response.data.depots })) },
        (errorData) => { dispatch(error({ response: errorData.response })) }
      )
  }
}

export default fetchDepots
