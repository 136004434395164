import { fetchAll } from '@client/api/wishlists'

const start = () => {
  return {
    type: 'FETCH_WISHLISTS_START'
  }
}

const success = ({ wishlists }) => {
  return {
    type: 'FETCH_WISHLISTS_SUCCESS',
    payload: { wishlists }
  }
}

const error = ({ response }) => {
  return {
    type: 'FETCH_WISHLISTS_ERROR',
    payload: { response }
  }
}

const fetchWishlists = () => {
  return (dispatch) => {
    dispatch(start())

    return fetchAll()
      .then(
        (response) => { dispatch(success({ wishlists: response.data.wishlists })) },
        (errorData) => { dispatch(error({ response: errorData.response })) }
      )
  }
}

export default fetchWishlists
