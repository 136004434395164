import React from 'react'
import { Provider } from 'react-redux'
import { i18n } from './localized-i18n.js'
import 'client/javascripts/i18n'

export default (ReactRailsUJS, store) => {
  ReactRailsUJS.originalGetConstructor = ReactRailsUJS.getConstructor
  ReactRailsUJS.getConstructor = (className) => {
    const Component = ReactRailsUJS.originalGetConstructor(className)

    return (props) => {
      if (props.i18n) {
        i18n.locale = props.i18n.locale
        i18n.defaultLocale = props.i18n.defaultLocale
        i18n.availableLocales = props.i18n.availableLocales
        i18n.useLocaleInPath = props.i18n.useLocaleInPath
      }

      return (
        <Provider store={store}>
          <Component {...props} />
        </Provider>
      )
    }
  }
}
