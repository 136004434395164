// React
import React from 'react'

// Libraries

// Components
import Mobile from './Mobile'
import Desktop from './Desktop'

// Shared
import isMobile from '@client/utils/isMobile'

const Picker = ({ onChange, pickupOpeningTimes, returnOpeningTimes, ...props }) => {
  const handleDepotSelect = (type, depot) => {
    switch (type) {
      case 'pickup':
        onChange('pickupDepot', depot)
        break
      case 'return':
        onChange('returnDepot', depot)
        break
    }
  }

  const handleTimeslotSelect = (type, timeslot) => {
    switch (type) {
      case 'pickup':
        onChange('pickupTimeslot', timeslot)
        break
      case 'return':
        onChange('returnTimeslot', timeslot)
        break
    }
  }

  const handleDateSelect = (type, date) => {
    switch (type) {
      case 'pickup':
        onChange('pickupDate', date)
        break
      case 'return':
        onChange('returnDate', date)
        break
    }
  }

  const WrapperComponent = isMobile() ? Mobile : Desktop

  return (
    <WrapperComponent
      handleDepotSelect={handleDepotSelect}
      handleTimeslotSelect={handleTimeslotSelect}
      handleDateSelect={handleDateSelect}
      {...props}
    />
  )
}

export default Picker
