import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'

const customDropdown = ({ innerProps, isDisabled }) => {
  return !isDisabled ? (
    <i {...innerProps} className="fas fa-sort-down bc-select__dropdown" />
  ) : null
}


customDropdown.propTypes = {
  innerProps: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired
}

const CustomSelectValue = ({ children, selectProps }) => {
  return(
    <>
      <i className={`text-muted fas fa-${selectProps.icon} custom-select-value__icon`} />
      <span className="ml-2 custom-select-value__label">{children}</span>
    </>
  )
}

// handle options group header click event
// hide and show the options under clicked group
const handleHeaderClick = id => {
  const node = document.querySelector(`#${id}`).parentElement.nextElementSibling
  const classes = node.classList

  if (classes.contains("collapsed")) {
    node.classList.remove("collapsed")
  } else {
    node.classList.add("collapsed")
  }
}

// Create custom GroupHeading component, which will wrap
// react-select GroupHeading component inside a div and
// register onClick event on that div
const CustomGroupHeading = props => {
  return (
    <div
      className="group-heading-wrapper"
      onClick={() => handleHeaderClick(props.id)}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

const Select = ({
  id,
  options,
  styles,
  onChange,
  value,
  defaultValue,
  getOptionLabel,
  getOptionValue,
  usePortal,
  placeholder,
  isOptionDisabled,
  components = {},
  icon,
  disabled
}) => {
  return (
    <ReactSelect
      id={id}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      classNamePrefix="bc-select"
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      components={{ DropdownIndicator: customDropdown, SingleValue: CustomSelectValue, GroupHeading: CustomGroupHeading, ...components }}
      styles={styles}
      menuPortalTarget={usePortal ? document.body : null}
      menuPosition="absolute"
      placeholder={placeholder || 'Select...'}
      isOptionDisabled={isOptionDisabled}
      isDisabled={disabled}
      icon={icon}
    />
  )
}

Select.propTypes = {
  options: PropTypes.array,
  styles: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  usePortal: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  components: PropTypes.object,
  disabled: PropTypes.bool
}

export default Select
