// React
import React from 'react'

// Libraries
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'

// Components
import Select from '../Select'
import DatePicker from '../DatePicker'
import OptionSidebar from './OptionSidebar'
import SelectPlaceholder from './SelectPlaceholder'

// Utils
import { t } from '@client/i18n/localize'

// Shared

const Desktop = ({
  errors,
  pickupDepots,
  returnDepots,
  values,
  handleDepotSelect,
  isDayAvailable,
  isTimeslotAvailable,
  range,
  handleDateSelect,
  pickupTimeslots,
  handleTimeslotSelect,
  returnTimeslots,
  hasError,
  firstAvailableAt
}) => {

  const groupReturnDepots = (depots) => {
    const grouped = groupBy(depots, (depot) => depot.domain.country_name)
    return map(grouped, (v, k) => ({ label: k, options: v }))
  }

  const returnDepotOptions = groupReturnDepots(returnDepots)

  return (
    <div className="bc-picker">
      <div className="bc-picker__block">
        <p className="bc-picker__title">{t('cart_picker.pickup')}</p>
        <div className="bc-form-row">
          <div className="bc-form-select bc-form-select--depot">
            <Select
              options={pickupDepots}
              value={values.pickupDepot}
              onChange={(value) => handleDepotSelect('pickup', value)}
              styles={{
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: '#dde1e3'
                })
              }}
              usePortal
              placeholder={<SelectPlaceholder value={t('cart_picker.pickup_depot')} icon="map-marker-alt" />}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              icon="map-marker-alt"
              components={{ Option: OptionSidebar }}
              id="pickup_depot"
            />
          </div>
          <div className="bc-form-row">
            <div className="bc-form-select">
              <DatePicker
                onChange={(value) => handleDateSelect('pickup', value)}
                errors={errors}
                isDayAvailable={isDayAvailable}
                firstAvailableAt={firstAvailableAt}
                range={range}
                type="pickup"
                placeholder={<SelectPlaceholder value={t('cart_picker.pickup_date')} icon="calendar-alt" />}
                popoverPosition="bottomLeft"
                value={values.pickupDate}
                disabled={!values.pickupDepot}
                icon="calendar-alt"
                id="pickup_date"
              />
            </div>
            <div className="bc-form-select">
              <Select
                options={pickupTimeslots}
                value={values.pickupTimeslot}
                onChange={(value) => handleTimeslotSelect('pickup', value)}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderColor: hasError(errors, 'pickup', 'timeslot') ? '#f26152' : '#dde1e3'
                  })
                }}
                usePortal
                placeholder={<SelectPlaceholder value={t('cart_picker.pickup_timeslot')} icon="clock" />}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.label}
                isOptionDisabled={(option) => !isTimeslotAvailable(option, 'pickup')}
                disabled={!values.pickupDate}
                icon="clock"
                id="pickup_timeslot"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bc-picker__block">
        <p className="bc-picker__title">{t('cart_picker.return')}</p>
        <div className="bc-form-row">
          <div className="bc-form-select bc-form-select--depot">
            <Select
              options={returnDepotOptions}
              value={values.returnDepot}
              onChange={(value) => handleDepotSelect('return', value)}
              styles={{
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: '#dde1e3'
                })
              }}
              usePortal
              placeholder={<SelectPlaceholder value={t('cart_picker.return_depot')} icon="map-marker-alt" />}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              components={{ Option: OptionSidebar }}
              icon="map-marker-alt"
              id="return_depot"
            />
          </div>
          <div className="bc-form-row">
            <div className="bc-form-select">
              <DatePicker
                onChange={(value) => handleDateSelect('return', value)}
                errors={errors}
                isDayAvailable={isDayAvailable}
                range={range}
                type="return"
                placeholder={<SelectPlaceholder value={t('cart_picker.return_date')} icon="calendar-alt" />}
                popoverPosition="bottomLeft"
                value={values.returnDate}
                disabled={!values.returnDepot}
                icon="calendar-alt"
                id="return_date"
              />
            </div>
            <div className="bc-form-select">
              <Select
                options={returnTimeslots}
                value={values.returnTimeslot}
                onChange={(value) => handleTimeslotSelect('return', value)}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderColor: hasError(errors, 'return', 'timeslot') ? '#f26152' : '#dde1e3'
                  })
                }}
                usePortal
                placeholder={<SelectPlaceholder value={t('cart_picker.return_timeslot')} icon="clock" />}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.label}
                isOptionDisabled={(option) => !isTimeslotAvailable(option, 'return')}
                disabled={!values.returnDate}
                icon="clock"
                id="return_timeslot"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Desktop
