import React, { useState, useRef } from 'react'
import { tween, easing } from 'popmotion'

// Components
import ProductList from './ProductList'

const ProductGroup = (props) => {
  const node = useRef(null)
  const [step, setStep] = useState(0)
  const [itemsVisible, setItemsVisible] = useState(0)

  const scrollTo = (from, to) => {
    const { easeIn, mirrored } = easing
    const easeInOut = mirrored(easeIn)

    tween({ from, to, ease: easeInOut }).start((x) => {
      node.current.scrollLeft = x
    })
  }

  const handleStep = (add) => {
    const containerWidth = node.current.clientWidth
    const itemWidth = node.current.querySelector('div').getBoundingClientRect().width
    const itemsVisible = Math.ceil(containerWidth / itemWidth)
    const maxStep = props.products.length - itemsVisible
    const newStep = Math.min(Math.max(step + add, 0), maxStep)

    scrollTo(step * itemWidth, newStep * itemWidth)
    setStep(newStep)
    setItemsVisible(itemsVisible)
  }

  const handeClickleft = () => {
    handleStep(-2)
  }

  const handeClickRight = () => {
    handleStep(2)
  }

  return (
    <div className="item-card-group">
      {step > 0 && (
        <div className="item-card-group__carousel--left" onClick={handeClickleft}>
          <i className="fas fa-chevron-left" />
        </div>
      )}

      <div className="row item-card-group-scroll-container" ref={node}>
        <ProductList {...props} />
      </div>

      {step + itemsVisible < props.products.length && (
        <div className="item-card-group__carousel--right" onClick={handeClickRight}>
          <i className="fas fa-chevron-right" />
        </div>
      )}
    </div>
  )
}

export default ProductGroup
