import { applyCoupon } from '@client/api/cart'

const start = ({ id, type }) => {
  return {
    type: 'CART_APPLY_COUPON_START',
    payload: { id, type }
  }
}

const success = ({ cart }) => {
  return {
    type: 'CART_APPLY_COUPON_SUCCESS',
    payload: { cart }
  }
}

const error = ({ response }) => {
  return {
    type: 'CART_APPLY_COUPON_ERROR',
    payload: { response }
  }
}

const applyCouponOnCart = ({ coupon, customerId }) => {
  return (dispatch, getState) => {
    const { cart } = getState()

    dispatch(start({ token: cart.token, coupon, customerId }))

    return applyCoupon({ token: cart.token, coupon, customerId })
      .then(
        (response) => dispatch(success(response.data)),
        (errorData) => dispatch(error({ token: cart.token, coupon, customerId, response: errorData.response }))
      )
  }
}

export default applyCouponOnCart
