import React, { useState, useEffect } from 'react'

// Components
import Mobile from './Mobile'
import Desktop from './Desktop'

// Utils
import isMobile from '@client/utils/isMobile'
import { countBy, times, flatMap } from 'lodash'

const AddOptionalAccessories = ({ visible, onClose, onClick, accessories, loading, success, productName, selectedAccessories = [], type, children }) => {
  const [selected, setSelected] = useState([])

  useEffect(() => {
    const count = countBy(selectedAccessories, (accessory) => accessory.product_id)
    const selected = flatMap(count, (v, k) => {
      return times(v, (i) => {
        return `${k}::${i}`
      })
    })
    setSelected(selected)
  }, [selectedAccessories.length])

  const isSelected = (accessoryId) => {
    return selected.indexOf(accessoryId) >= 0
  }

  const handleChange = (accessoryId, checked) => {
    if (checked) {
      setSelected(prev => [...prev, accessoryId])
    } else {
      setSelected(prev => prev.filter((id) => id !== accessoryId))
    }
  }

  const handleClick = (e) => {
    e.preventDefault()

    onClick({ accessoryIds: selected.map(x => x.split('::')[0]) })
  }

  const Component = isMobile() ? Mobile : Desktop
  return (
    <Component
      productName={productName}
      accessories={accessories}
      isSelected={isSelected}
      onChange={handleChange}
      onClick={handleClick}
      selected={selected}
      loading={loading}
      success={success}
      visible={visible}
      onClickOutside={onClose}
      type={type}
    >
      {children}
    </Component>
  )
}

export default AddOptionalAccessories
