import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import { i18n } from '../../../../app/javascript/packs/localized-i18n'

import window from 'global'

const instance = applyCaseMiddleware(
  axios.create(),
  {
    ignoreHeaders: true,
    ignoreParams: true,
    preservedKeys: (input) => {
      const keysToTransform = [
        "image_thumb",
        "image_thumb_large",
        "price_in_cents",
        "formatted_coupon_discount",
        "formatted_discount",
        "formatted_grand_total",
        "formatted_insurance",
        "formatted_price",
        "formatted_subtotal",
        "formatted_total",
        "formatted_vat",
        "formatted_vat_amount",
      ];
      return !keysToTransform.includes(input);
    }
  },
)

window.axiosPendingRequests = 0

instance.interceptors.request.use(config => {
  config.params = {
    // add your default ones
    locale: i18n.locale,
    // spread the request's params
    ...config.params,
  }
  return config
})

instance.interceptors.request.use(request => {
  if (request.method !== 'get') {
    const token = document.querySelector('[name=csrf-token]')?.content
    request.headers['X-CSRF-TOKEN'] = token
  }

  return request
}, (error) => {
  return Promise.reject(error)
})

// Track pending requests
instance.interceptors.request.use(config => {
  window.axiosPendingRequests++

  return config
}, (error) => {
  return Promise.reject(error)
})

// Track pending requests
instance.interceptors.response.use(response => {
  window.axiosPendingRequests--

  return response
}, (error) => {
  window.axiosPendingRequests--

  return Promise.reject(error)
})

export default instance
