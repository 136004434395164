import { book } from '@client/api/cart'

const cartBookStart = ({ id, type, quantity, accessoryIds }) => {
  return {
    type: 'CART_BOOK_START',
    payload: { id, type, quantity, accessoryIds }
  }
}

const cartBookSuccess = ({ cart }) => {
  return {
    type: 'CART_BOOK_SUCCESS',
    payload: { cart }
  }
}

const cartBookError = ({ response }) => {
  return {
    type: 'CART_BOOK_ERROR',
    payload: { response }
  }
}

const bookProductToCart = ({ id, type, quantity = 1, accessoryIds }) => {
  return (dispatch, getState) => {
    const { cart } = getState()

    dispatch(cartBookStart({ id, type, quantity, accessoryIds }))

    return book({ token: cart.token, type, id, quantity, accessoryIds })
      .then(
        (response) => dispatch(cartBookSuccess(response.data)),
        (error) => dispatch(cartBookError({ response: error.response }))
      )
  }
}

export default bookProductToCart
