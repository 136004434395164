import moment from '@client/i18n/moment'
import { enUS, nl, de, fr } from "date-fns/locale";

import allTranslations from './translations'
import capitalize from 'lodash/capitalize'

import { i18n } from '../../../../app/javascript/packs/localized-i18n'

const t = (...args) => i18n.t(...args)

const translations = () => {
  return allTranslations['nl']
}

const formatDate = (date, type) => {
  const formats = translations().formats.date
  const format = formats[type] || formats.default
  return moment(date).format(format)
}

const date = (datetime) => {
  const format = 'dd DD MMM Y'
  const day = moment(datetime).format('dd')
  return capitalize(moment(datetime).format(format))
}

const calendarDate = (datetime, small = false) => {
  const params = {
    lastDay: `[${t('date.yesterday')}]`,
    sameDay: `[${t('date.today')}]`,
    nextDay: `[${t('date.tomorrow')}]`,
    lastWeek: small ? 'dd' : 'dddd',
    nextWeek: small ? 'dd' : 'dddd',
    sameElse: small ? 'dd' : 'dddd'
  }
  const format = small ? ', D MMM HH:mm' : ', D MMMM HH:mm'
  const day = moment(datetime).calendar(null, params)

  return capitalize(day) + moment(datetime).format(format)
}

const getDateFnsLocale = () => {
  switch (i18n.locale) {
    case 'nl':
      return nl
    case 'fr':
      return fr
    case 'de':
      return de
    default:
      return enUS
  }
}

const translatePeriodLabel = (label) => {
  let displayLabel = ''

  // Check if the label contains a charge period (1 day, 10 week)
  if (/^\d+\s\w+/gm.test(label)) {
    const [amount, chargeType] = label.split(' ')

    displayLabel = t(`charge_types.${chargeType}`, { count: parseInt(amount) })
  }

  return displayLabel
}

export { t, translations, formatDate, calendarDate, date, getDateFnsLocale, translatePeriodLabel }
