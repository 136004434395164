import React, { useState, forwardRef, useImperativeHandle } from 'react'

import { t } from '@client/i18n/localize'
import { getQuery, resetQuery } from '@client/utils/tweakwise'

const SearchInput = forwardRef(({ initialValue, modifier, autoFocus, onIconClick, id, onChange }, ref) => {
  const queryValue = initialValue || getQuery(window.location.hash)
  const [query, setQuery] = useState(queryValue || '')

  useImperativeHandle(ref, () => ({
    reset() {
      setQuery('')
      resetQuery()
    }
  }))

  const handleChangeQuery = (e) => {
    setQuery(e.target.value)
    onChange && onChange(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <div className={`quicksearch__container ${modifier ? `quicksearch__container--${modifier}` : ''}`}>
      <form onSubmit={handleSubmit}>
        <i onClick={onIconClick} className="fas fa-search quicksearch__icon" />
        <input
          type="text"
          placeholder={t('quicksearch.search')}
          name="search-query"
          id={id || "tweakwise-input-desktop"}
          className="quicksearch__input"
          value={query}
          onChange={handleChangeQuery}
          autoFocus={autoFocus}
          autoComplete="off"
        />
      </form>
    </div>
  )
})

export default SearchInput
