import React from 'react'

import Tippy from '@tippyjs/react'

const instance = ({ content, visible, onClickOutside, theme = 'custom', children}) => {
  const popperOptions = {
    strategy: 'fixed',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom', 'right', 'left', 'top'],
        },
      }
    ]
  }

  return (
    <Tippy
      content={content}
      visible={visible}
      onClickOutside={onClickOutside}
      theme={theme}
      interactive={true}
      maxWidth="80vw"
      popperOptions={popperOptions}
      >{children}</Tippy>
  )
}

export default instance
