import React, { useState, useEffect } from 'react'

// Libraries
import { useDispatch } from 'react-redux'

// Actions
import { createWishlist } from '@client/redux/actions/wishlists'

// Utils
import { t } from '@client/i18n/localize'

// Components
import Modal from '@client/react/components/Modal'

const NewList = ({ product, onBack }) => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [valid, setValid] = useState(false)

  useEffect(() => {
    setValid(name && name.length > 0)
  }, [name])

  const handleClickBack = (e) => {
    e.preventDefault()
    onBack()
  }

  const handleChangeName = (e) => {
    setName(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (valid) {
      dispatch(createWishlist({ name, productIds: [product.id] })).then(() => {
        onBack()
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Header>
        <Modal.Title>{t('wishlists.create_new_list')}</Modal.Title>
        <Modal.SubTitle>{product.name}</Modal.SubTitle>
      </Modal.Header>
      <Modal.Body>
        <div className="hstack gap-3">
          <div className="d-none d-sm-block col-auto">
            <img src={product.image} alt="product-image" className="img-fluid" width="125px" loading="lazy" />
          </div>
          <div className="col">
            <input
              minLength="1"
              required=""
              className="form-control"
              type="text"
              name="new-favorite-name"
              onChange={handleChangeName}
              placeholder={t('wishlists.name_of_list')}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary mr-2"
          onClick={handleClickBack}
        >{t('wishlists.back_to_list')}</button>
        <button
          type="submit"
          className={`btn btn-primary ${valid ? '' : 'disabled'}`}
          disabled={!valid}
        >{t('wishlists.create')}</button>
      </Modal.Footer>
    </form>
  )
}

export default NewList
