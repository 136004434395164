import React, { useState, useEffect, useRef } from 'react'

import Modal from '@client/react/components/Modal'
import SearchInput from './SearchInput'

const positionModal = () => {
  const modalElement = document.querySelector('.bc-modal');
  if (!modalElement) return;

  const inputElement = document.querySelector('#tweakwise-input-mobile');
  const searchInputPosition = inputElement.getBoundingClientRect();

  const topPosition = searchInputPosition.bottom + 10;
  modalElement.style.top = `${topPosition}px`;
  modalElement.style.height = `calc(100vh - ${topPosition}px)`;
}

const SearchBar = () => {
  const [show, setShow] = useState(null)
  const searchRef = useRef()

  useEffect(() => {
    if(!show) {
      return
    }

    // Trigger tweakwise again to attach event handler on input
    window['twn-starter-instance'] && window['twn-starter-instance'].refresh()
  }, [show])

  useEffect(() => {
    if (!show) {
      return
    }

    positionModal()
  }, [show]);

  const handleClose = (event) => {
    event && event.preventDefault()
    setShow(false)
    searchRef.current.reset()
  }

  const handleChange = (value) => {
    setShow(value.length > 0)
  }

  return (
    <>
      <SearchInput
        id="tweakwise-input-mobile"
        ref={searchRef}
        expanded={false}
        onChange={handleChange}
      />

      <Modal
        onClose={handleClose}
        show={show}
      >
        <Modal.Body>
          <div id="tweakwise-output-mobile"></div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SearchBar
