import { i18n } from '../../../../app/javascript/packs/localized-i18n'
import { t } from '@client/i18n/localize'

const url = (path) => {
  if (i18n.useLocaleInPath) {
    return `/${i18n.locale}/${path}`
  } else {
    return `/${path}`
  }
}

const productsPath = (path = null) => {
  const producten = t('routes.producten')

  if (path) {
    return url(`${producten}/${path}`)
  } else {
    return url(`${producten}`)
  }
}

const productPath = (id) => {
  const product = t('routes.product')

  return url(`${product}/${id}`)
}

export { url, productsPath, productPath }
