import { ReactNotifications, Store } from 'react-notifications-component'

const notification = ({ title, message, type = 'default' }) => {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000
    }
  })
}

export { ReactNotifications as default, notification }
