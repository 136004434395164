/**
 * Get popover position from target dom node in a desired direction and with an optional offset
 *
 * @param {Node} node
 *  Target node
 * @param {String} direction
 *  One of: top, right, bottom, left, topRight, bottomLeft, topLeft, bottomRight
 * @param {Boolean} scrollable
 *  Offset from target in pixels
 * @example
 *   getPopoverPosition(node, 'left', true)
 * @return {Object} style properties for desired position
 */
export default function getPopoverPosition (node, direction, scrollable, offset = 0, clampX) {
  const boundingRect = node.getBoundingClientRect()

  const top = scrollable ? boundingRect.top + window.scrollY : boundingRect.top
  const bottom = scrollable ? boundingRect.bottom + window.scrollY : boundingRect.bottom

  switch (direction) {
    case 'top':
      return {
        top: top - offset,
        left: clampX || boundingRect.left + boundingRect.width / 2,
        transform: 'translate(-50%, -100%)'
      }
    case 'right':
      return {
        top: top + boundingRect.height / 2,
        left: boundingRect.right + offset,
        transform: 'translateY(-50%)'
      }
    case 'bottom':
      return {
        top: bottom + offset,
        left: clampX || boundingRect.left + boundingRect.width / 2,
        transform: 'translateX(-50%)'
      }
    case 'left':
      return {
        top: top + boundingRect.height / 2,
        left: boundingRect.left - offset,
        transform: 'translate(-100%, -50%)'
      }
    case 'topRight':
      return {
        // 20 is the offset the popover arrow has here
        top: top + boundingRect.height / 2 + 20,
        left: boundingRect.right + offset,
        transform: 'translateY(-100%)'
      }
    case 'topLeft':
      return {
        top: top + boundingRect.height / 2 + 20,
        left: boundingRect.left - offset,
        transform: 'translate(-100%, -100%)'
      }
    case 'bottomRight':
      return {
        top: top + boundingRect.height / 2 - 20,
        left: boundingRect.right + offset,
        transform: 'translateY(0%)'
      }
    case 'bottomLeft':
      return {
        top: bottom + offset,
        left: clampX || boundingRect.left
      }
  }
}
