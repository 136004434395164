import React from 'react'

// Utils
import { productsPath } from '@client/utils'
import { t } from '@client/i18n/localize'

const NoResults = () => {
  return (
    <div className="col-12 product-empty">
      <h2 className="product-empty__title">{t('product_empty.title')}</h2>
      <div>
        <div className="product-empty__check">
          <i className="fas fa-check"></i>
          <span>{t('product_empty.text1')}</span>
        </div>
        <div className="product-empty__check">
          <i className="fas fa-check"></i>
          <span>{t('product_empty.text2')}</span>
        </div>
      </div>
      <a href={productsPath()} className="product-empty__link">{t('product_empty.reset_filters')}</a>
    </div>
  )
}

export default NoResults
