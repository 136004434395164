// React
import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

// Components

// Libraries
import { t } from '@client/i18n/localize'

// Shared

const Sidebar = ({ data: depot, style }) => {
  return (
    <div style={style} className="bc-custom-option-popover vstack gap-3">
      <div className="hstack gap-3 justify-content-between">
        <div>
          <h2 className="h3 mb-0">{depot.name}</h2>
          <p className="text-gray mb-0">{t(`depot.store_types.${depot.store_type}`)}</p>
        </div>
        <img src={depot.image.thumb} className="w-5 h-5 rounded-circle" />
      </div>
      <span>{depot.excerpt}</span>
      <div className="hstack gap-3">
        <i className="fas fa-map-marker-alt text-large text-primary"></i>
        <div className="d-flex flex-column">
          <span>{depot.street} {depot.street_no}</span>
          <span>{depot.zipcode} {depot.city}</span>
        </div>
      </div>
      {depot.opening_hours.length > 0 && (
        <div className="hstack gap-3">
          <i className="fas fa-clock text-large text-primary"></i>
          <div className="d-block">
            {depot.opening_hours.map((openingHour, i) => (
              <div key={i} className="d-flex justify-content-between gap-2">
                <span className="text-medium">{openingHour.days}</span>
                <span>{openingHour.time}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const Option = (props) => {
  const [entered, setEntered] = useState(false)
  const [popoverPosition, setPopoverPosition] = useState({ left: -10000, top: -10000 })

  useEffect(() => {
    if (ref && ref.current) {
      const boundingRect = ref.current.closest('.bc-select__menu-list').getBoundingClientRect()
      const popoverPosition = {
        position: 'fixed',
        top: boundingRect.top - 1,
        left: boundingRect.left + boundingRect.width - 1
      }

      setPopoverPosition(popoverPosition)
    }
  }, [ref])

  const ref = useRef(null)

  const handleMouseEnter = () => {
    setEntered(true)
  }
  const handleMouseLeave = () => {
    setEntered(false)
  }

  const classNames = (props, entered) => {
    let classes = ['bc-select__option']

    if (props.isSelected) { classes.push('bc-select__option--is-selected') }
    if (entered) { classes.push('bc-select__option--borderless') }

    return classes.join(' ');
  }

  return (
    <div
      className={classNames(props, entered)}
      ref={props.innerRef} {...props.innerProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div ref={ref} className="bc-custom-option__label">
        {props.label}
        {entered && createPortal(<Sidebar style={popoverPosition} {...props} />, document.body)}
      </div>
    </div>
  )
}

export default Option
