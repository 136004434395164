import { create } from '@client/api/wishlists'

const start = ({ name, productIds }) => {
  return {
    type: 'CREATE_WISHLIST_START',
    payload: { name, productIds }
  }
}

const success = ({ name, productIds, wishlist }) => {
  return {
    type: 'CREATE_WISHLIST_SUCCESS',
    payload: { name, productIds, wishlist }
  }
}

const error = ({ name, productIds, response }) => {
  return {
    type: 'CREATE_WISHLIST_ERROR',
    payload: { name, productIds, response }
  }
}

const createWishlist = ({ name, productIds }) => {
  return (dispatch) => {
    dispatch(start({ name, productIds }))

    return create({ name, productIds })
      .then(
        (response) => { dispatch(success({ name, productIds, wishlist: response.data.wishlist })) },
        (errorData) => { dispatch(error({ name, productIds, response: errorData.response })) }
      )
  }
}

export default createWishlist
