import { map, toPairs, sortBy, fromPairs } from 'lodash'
import { productsPath } from '@client/utils'

const filterPath = (filterSlug, filterGroupSlug, filters) => {
  return filtersPath(toggleFilter(filterSlug, filterGroupSlug, filters, true))
}

const filtersPath = (filters) => {
  const sortedFilters = fromPairs(sortBy(toPairs(filters)))
  const path = map(sortedFilters, (filters, groupSlug) => {
    if (filters.length === 0) return null
    return `${groupSlug}/${sortBy(filters).join('--')}`
  }).join('/')

  return productsPath(path)
}

const toggleFilter = (filterSlug, filterGroupSlug, filters={}, addOnly=false) => {
  const isAdded = isFilterPresent(filterSlug, filterGroupSlug, filters)

  if (isAdded && addOnly) {
    return filters
  }

  const newFilters = { ...filters }
  const currentFilters = newFilters[filterGroupSlug] = (newFilters[filterGroupSlug] || []).concat([])

  if (isAdded) {
    currentFilters.splice(currentFilters.indexOf(filterSlug), 1)
  } else {
    currentFilters.push(filterSlug)
  }

  return newFilters
}

/**
 * Checks if given filter slug is present.
 *
 * @param {String} filterSlug
 * @param {String} filterGroupSlug
 * @param {Object} filters
 */
const isFilterPresent = (filterSlug, filterGroupSlug, filters) => {
  return filters[filterGroupSlug] && filters[filterGroupSlug].indexOf(filterSlug) >= 0
}

export { filterPath, filtersPath, toggleFilter, isFilterPresent, productsPath }
