import React, { useState, useRef } from 'react'

// Libraries
import { t } from '@client/i18n/localize'
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Components
import Modal from '@client/react/components/Modal'

const Share = ({ url }) => {
  const [show, setShow] = useState(null)
  const [copySuccess, setCopySuccess] = useState(false)

  const handleOpen = (e) => {
    e && e.preventDefault()
    setShow(true)
    setCopySuccess(false)
  }

  const handleClose = (e) => {
    e && e.preventDefault()
    setShow(false)
  }

  const onCopy = () => {
    setCopySuccess(true)
  }

  return (
    <>
      <div className="text-right py-3">
        <button type="button" className="btn btn-link" onClick={handleOpen}>{t('shopping_cart.share.link')}</button>
      </div>

      <Modal onClose={handleClose} show={show}>
        <Modal.Header>
          <Modal.Title>{t('shopping_cart.share.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="share-cart">
            <p>{t('shopping_cart.share.text')}</p>
            <input type="text" value={url} className="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CopyToClipboard onCopy={onCopy} text={url}>
            {copySuccess ?
              <div className="btn btn-primary">
                <i className="fas fa-check" />
              </div>
              : <button className="btn btn-primary">{t('copy')}</button>
            }
          </CopyToClipboard>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Share
