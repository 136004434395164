import React from 'react'

const Filter = ({ name, path, count, selected, handleToggleFilter, navigate }) => {
  const onChange = (e) => {
    handleToggleFilter()
  }

  const handleClick = (e) => {
    if (!navigate) {
      e.preventDefault()
      handleToggleFilter()
    }
  }

  return (
    <label className="hstack gap-2 mb-1 product-filter__option">
      <input checked={selected} type="checkbox" onClick={onChange} />
      <div className="hstack justify-content-between flex-grow-1" onClick={handleClick}>
        <a className="text-dark" href={path}>
          {name}
        </a>
        <span className="text-small text-gray">({count})</span>
      </div>
    </label>
  )
}

export default Filter
