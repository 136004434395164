import { update } from '@client/api/carts_products'

const start = (params) => {
  return {
    type: 'CARTS_PRODUCT_UPDATE_START',
    payload: params
  }
}

const success = ({ cart }) => {
  return {
    type: 'CARTS_PRODUCT_UPDATE_SUCCESS',
    payload: { cart }
  }
}

const error = ({ response }) => {
  return {
    type: 'CARTS_PRODUCT_UPDATE_ERROR',
    payload: { response }
  }
}

const updateCartsProduct = ({ id, accessoryIds, quantity }) => {
  return (dispatch) => {
    dispatch(start({ id, accessoryIds, quantity }))

    return update({ id, accessoryIds, quantity })
      .then(
        (response) => dispatch(success(response.data)),
        (errorData) => dispatch(error({ response: errorData.response }))
      )
  }
}

export default updateCartsProduct
