import React, { forwardRef } from 'react'

// Components
import Icon from './Icon'

const Link = forwardRef(({ onClick, loading, label }, ref) => {
  const handleClick = (e) => {
    e.preventDefault()
    onClick && onClick()
  }

  if (loading || success) {
    return <Icon ref={ref} loading={loading} success={success} />
  } else {
    return (
      <a ref={ref} className="add-to-cart" onClick={handleClick} href="#">
        {label}
        <i className="fas fa-shopping-cart add-to-cart__icon animated animated-zoom" />
      </a>
    )
  }
})

export default Link
