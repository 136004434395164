import { fetch } from '@client/api/wishlists'

const start = () => {
  return {
    type: 'FETCH_WISHLIST_START'
  }
}

const success = ({ wishlist }) => {
  return {
    type: 'FETCH_WISHLIST_SUCCESS',
    payload: { wishlist }
  }
}

const error = ({ response }) => {
  return {
    type: 'FETCH_WISHLIST_ERROR',
    payload: { response }
  }
}

const fetchWishlist = ({ id }) => {
  return (dispatch) => {
    dispatch(start())

    return fetch({ id })
      .then(
        (response) => { dispatch(success({ wishlist: response.data.wishlist })) },
        (errorData) => { dispatch(error({ response: errorData.response })) }
      )
  }
}

export default fetchWishlist
