const open = () => {
  return {
    type: 'OPEN_PICKER',
    payload: {}
  }
}

const openPicker = () => {
  return (dispatch) => {
    return dispatch(open())
  }
}

export default openPicker
