import React, { useState, useEffect } from 'react'

// API
import { fetchPoll, votePoll } from '@client/api/poll'

// Utils
import { t } from '@client/i18n/localize'

// Components
import Vote from './Vote'
import Results from './Results'

const Poll = ({ title, description, options, onSubmit, step }) => {
  return (
    <div className="poll">
      <div className="hstack justify-content-between">
        <div className="text-uppercase text-primary text-small text-medium">{t('poll.title')}</div>
      </div>
      <h2 className="h3">{title}</h2>
      <p>{description}</p>

      {step === 'vote' && <Vote onSubmit={onSubmit} options={options} />}
      {step === 'results' && <Results options={options} />}
    </div>
  )
}

const Container = () => {
  const [step, setStep] = useState('vote')
  const [poll, setPoll] = useState(null)

  useEffect(() => {
    fetchPoll().then(({ data }) => {
      if (data.meta.voted) {
        setStep('results')
      }

      setPoll(data.poll)
    })
  }, [])

  const handleVote = (id) => {
    votePoll({ option: id }).then(({ data }) => {
      setPoll(data.poll)
      setStep('results')
    })
  }

  if (poll) {
    return (
      <Poll
        title={poll.title}
        description={poll.description}
        options={poll.poll_options}
        onSubmit={handleVote}
        step={step}
      />
    )
  } else {
    return null
  }
}

export default Container
