import React, { useState } from 'react'

// Utils
import useClickOutside from '@client/react/hooks/useClickOutside'

// Components
import SearchInput from './SearchInput'

const SearchTablet = () => {
  const [expanded, setExpanded] = useState(false)

  const [showIcon, setShowIcon] = useState(true)
  const [showSearch, setShowSearch] = useState(false)

  const handleToggleExpand = (expanded) => {
    setExpanded(!expanded)
    handleShowIcon(expanded)
    handleShowSearch(expanded)
  }

  const handleShowIcon = (expanded) => {
    if (expanded) {
      setShowIcon(true)
    } else {
      setShowIcon(false)
    }
  }

  const handleShowSearch = (expanded) => {
    if (expanded) {
      const timer = setTimeout(() => {
        setShowSearch(false)
      }, 200)
      return () => clearTimeout(timer)
    } else {
      const timer = setTimeout(() => {
        setShowSearch(true)
      }, 50)
      return () => clearTimeout(timer)
    }
  }

  const node = useClickOutside(() => {
    if (expanded) {
      handleToggleExpand(expanded)
    }
  })

  return (
    <div
      className={`header-tablet__search-container ${
        expanded ? 'header-tablet__search-container--expanded' : 'header-tablet__search-container--not-expanded'
      }`}
      ref={node}
    >

      <a className={`header-icon ${showIcon ? '' : 'hide'}`} onClick={() => handleToggleExpand(expanded)}>
        <i className="fas fa-search header-icon__i" />
      </a>

      <SearchInput id="tweakwise-input-tablet" className={showSearch ? '' : 'hide'} onIconClick={() => handleToggleExpand(expanded)} expanded={false} autoFocus />
    </div>
  )
}

export default SearchTablet
