import React, { useState, useEffect } from 'react'

// Libraries
import { useDispatch, useSelector } from 'react-redux'
import moment from '@client/i18n/moment'

// Actions
import { updateCart } from '@client/redux/actions/cart'
import { openPicker } from '@client/redux/actions/picker'

// Utils
import { t } from '@client/i18n/localize'
import { getTimeslot } from '@client/utils/timeslots'

// Components
import Insurance from './Insurance'
import Delivery from './Delivery'
import Validation from './Validation'

const Prices = ({ reservePath, readonly, orderButtonMessage }) => {
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const timeslots = useSelector(state => state.timeslots)
  const depots = useSelector(state => state.depots)
  const openingTimes = useSelector(state => state.openingTimes)
  const company = useSelector(state => state.company)

  const [hasInsurance, setHasInsurance] = useState(false)
  const [showInsuranceInfo, setShowInsuranceInfo] = useState(null)

  const [wantsDelivery, setWantsDelivery] = useState(false)
  const [showDeliveryInfo, setShowDeliveryInfo] = useState(null)

  const validationValues = () => {
    const values = {
      pickupTime: cart.pickup_time && moment(cart.pickup_time),
      pickupDate: cart.pickup_time && moment(cart.pickup_time).toDate(),
      pickupDepot: depots.find((depot) => depot.id === cart.pickup_depot_id),
      returnTime: cart.return_time && moment(cart.return_time),
      returnDate: cart.return_time && moment(cart.return_time).toDate(),
      returnDepot: depots.find((depot) => depot.id === cart.return_depot_id)
    }

    if (values.pickupTime && values.returnTime) {
      values.pickupTimeslot = getTimeslot(values.pickupTime, timeslots, openingTimes, values.pickupDepot.id, 'pickup')
      values.returnTimeslot = getTimeslot(values.returnTime, timeslots, openingTimes, values.returnDepot.id, 'return')
    }

    return values
  }

  const validation = new Validation({ openingTimes, ...validationValues() })
  const dataRequired = [cart.return_depot_id, cart.pickup_depot_id, cart.pickup_time, cart.return_time]
  const isValid = dataRequired.indexOf(undefined) === -1 && dataRequired.indexOf(null) === -1
  const canReserve = validation.errors.length === 0 && isValid

  useEffect(() => {
    setHasInsurance(cart.wants_insurance)
  }, [cart.wants_insurance])

  useEffect(() => {
    setWantsDelivery(cart.delivery_service)
  }, [cart.delivery_service])

  const handleToggleInsurance = () => {
    setHasInsurance(!hasInsurance)
    dispatch(updateCart({ wants_insurance: !hasInsurance }))
  }

  const handleShowInsuranceInfo = () => {
    setShowInsuranceInfo(!showInsuranceInfo)
  }

  const handleToggleDelivery = () => {
    setWantsDelivery(!wantsDelivery)
    dispatch(updateCart({ delivery_service: !wantsDelivery }))
  }

  const handleShowDeliveryInfo = () => {
    setShowDeliveryInfo(!showDeliveryInfo)
  }

  const handleReserveClick = (e) => {
    e.preventDefault()

    if (canReserve && reservePath) {
      window.location = reservePath
    } else {
      dispatch(openPicker())
    }
  }

  return (
    <>
      <div className="vstack gap-2 mb-4">
        <div className="hstack justify-content-between text-medium">
          <span>{t('prices.subtotal')}</span>
          <span>{cart.formattedSubtotal}</span>
        </div>
        {cart.discount_in_cents > 0 && (
          <div className="hstack justify-content-between">
            <span>{t('prices.discount')}</span>
            <span>{cart.formattedDiscount}</span>
          </div>
        )}
        {cart.coupon_discount_in_cents > 0 && (
          <div className="hstack justify-content-between">
            <span>{t('prices.discount')}</span>
            <span>{cart.formattedCouponDiscount}</span>
          </div>
        )}
        <div className="hstack justify-content-between">
          <div className="hstack gap-2">
            {!readonly && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="insurance"
                  name="insurance"
                  type="checkbox"
                  onChange={handleToggleInsurance}
                  checked={hasInsurance}
                  />
                <label className="form-check-label" htmlFor="insurance">{t('prices.insurance')}</label>
              </div>
            )}
            {readonly && t('prices.insurance')}
            <i onClick={handleShowInsuranceInfo} className="fas fa-info-circle" role="button" />
            <Insurance onClose={handleShowInsuranceInfo} show={showInsuranceInfo} />
          </div>
          <span>{cart.formattedInsurance}</span>
        </div>
        {company.delivery_service_price_from && <div className="hstack justify-content-between">
          <div className="hstack gap-2">
            {!readonly && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="delivery_service"
                  name="delivery_service"
                  type="checkbox"
                  onChange={handleToggleDelivery}
                  checked={wantsDelivery}
                />
                <label className="form-check-label" htmlFor="delivery_service">{t('prices.delivery_service')}</label>
              </div>
            )}
            {readonly && t('prices.delivery_service')}
            <i onClick={handleShowDeliveryInfo} className="fas fa-info-circle" role="button" />
            <Delivery onClose={handleShowDeliveryInfo} show={showDeliveryInfo} />
          </div>
          <span className="text-italic">{t('prices.delivery_price_from', { price: company.delivery_service_price_from })}</span>
        </div>}
        {cart.total_in_cents !== cart.subtotal_in_cents && (
          <>
            <hr className="m-0" />
            <div className="hstack justify-content-between text-medium">
              <span>{t('prices.subtotal')}</span>
              <span>{cart.formattedTotal}</span>
            </div>
          </>
        )}
        {cart.vat_in_cents > 0 && (
          <div className="hstack justify-content-between">
            <span>{t('prices.tax_amount', { percentage: cart.vat_amount })}</span>
            <span>{cart.formattedVat}</span>
          </div>
        )}
        <hr className="m-0" />
        <div className="hstack justify-content-between text-medium text-large">
          <span>{t('prices.total')}</span>
          <span>{cart.formattedGrandTotal}</span>
        </div>
      </div>

      {!readonly && (
        <div className="reservation-button-wrapper">
          <a href="#" onClick={handleReserveClick} className={`btn w-100 ${canReserve ? 'btn-primary' : 'btn-secondary'}`}>
            {t('shopping_cart.reserve')}
          </a>
          { orderButtonMessage && (
            <p className="text-small mt-2 mb-0 text-primary text-center">
              {orderButtonMessage}
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default Prices
