// React
import React from 'react'

// Components
import Calendar from '../Calendar'

// Libraries

// Shared

const Date = ({ onChange, isDayAvailable, range, type, errors }) => {
  const selectedDay = (type, range) => {
    const date = {
      "pickup": range.from,
      "return": range.to || range.from
    }

    return date[type]
  }

  return (
    <Calendar
      onChange={onChange}
      isDayAvailable={isDayAvailable}
      range={range}
      type={type}
      selectedDay={selectedDay(type, range)}
    />
  )
}

export default Date
