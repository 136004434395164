import axios from '@client/lib/axios'

export const updateQuantity = ({ id, quantity }) => {
  return axios({
    method: 'patch',
    url: `/api/2/carts_products/${id}/update_quantity`,
    params: {
      quantity: quantity
    }
  })
}

export const update = ({ id, accessoryIds, quantity }) => {
  return axios({
    method: 'patch',
    url: `/api/2/carts_products/${id}`,
    params: {
      quantity: quantity,
      accessory_ids: accessoryIds
    }
  })
}
