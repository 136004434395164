import React, { useRef, useEffect } from 'react'

// Utils
import { isEmpty } from 'lodash'

const ModalTitle = ({ children }) => {
  return (
    <h1 className="bc-modal__title">{children}</h1>
  )
}

const ModalSubTitle = ({ children }) => {
  return (
    <h2 className="bc-modal__subtitle">{children}</h2>
  )
}

const ModalHeader = ({ children, border }) => {
  const modifiers = []

  if (border) { modifiers.push('border') }

  return (
    <div className={`bc-modal__header ${modifiers.map(m => `bc-modal__header--${m}`)}`}>
      {children}
    </div>
  )
}

const ModalBody = ({ children }) => {
  return (
    <div className="bc-modal__body">{children}</div>
  )
}

const ModalFooter = ({ children }) => {
  return (
    <div className="bc-modal__footer">{children}</div>
  )
}

const Modal = ({ children, onClose, onClickOutside, show }) => {
  const backgroundNode = useRef(null)

  const handleOutsideClick = (e) => {
    if (e.target === backgroundNode.current) {
      (onClickOutside && onClickOutside()) || (onClose && onClose())
    }
  }

  const handleToggleShow = (show) => {
    const html = document.querySelector('html')

    if (show) {
      if (isEmpty(html.style.top)) {
        // Only set top when it's not set
        html.style.top = `-${window.scrollY}px`
      }
      html.classList.add('scroll-lock')
    } else {
      if (document.querySelector('.bc-modal')) {
        // Don't hide when another modal is present
        return
      }

      const scrollY = html.style.top;

      if (isEmpty(scrollY)) {
        // Don't reset scroll since Modal has not been shown yet
        return
      }

      html.style.top = '';
      html.classList.remove('scroll-lock')
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }

  useEffect(() => {
    if (show !== null) {
      handleToggleShow(show)
    }
  }, [show])

  if (!show) return null

  return (
    <div className="bc-modal" onClick={handleOutsideClick} ref={backgroundNode}>
      <div className="bc-modal-box">
        {onClose && <i className="fas fa-close bc-modal__close" onClick={onClose} />}
        {children}
      </div>
    </div>
  )
}

export default Object.assign(Modal, {
  Header: ModalHeader,
  Title: ModalTitle,
  SubTitle: ModalSubTitle,
  Body: ModalBody,
  Footer: ModalFooter
})
