import React, { useState } from "react"

// Libraries
import { useDispatch, useSelector } from "react-redux"

// Utils
import { formatDate, t } from '@client/i18n/localize'

// Actions
import { openPicker } from '@client/redux/actions/picker'

const Information = ({ readonly=false }) => {
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)

  const dataRequired = [cart.return_depot_id, cart.pickup_depot_id, cart.pickup_time, cart.return_time]
  const isValid = dataRequired.indexOf(undefined) === -1 && dataRequired.indexOf(null) === -1

  const pickupDepot = cart.pickup_depot
  const returnDepot = cart.return_depot

  const handleEditPickup = (e) => {
    e.preventDefault()
    dispatch(openPicker())
  }

  const handleEditReturn = (e) => {
    e.preventDefault()
    dispatch(openPicker())
  }

  if (!isValid) {
    return (
      <div className="alert alert-warning">
        <i className="fas fa-exclamation-circle" />
        <div>
          <a href="#" className="alert-link" onClick={handleEditPickup}>{t('shopping_cart.choose_period_1')}</a> {t('shopping_cart.choose_period_2')}
        </div>
      </div>
    )
  }

  return (
    <div className="vstack gap-3">
      <div className="vstack gap-2">
        <div className="hstack gap-3 justify-content-between h6">
          {t('shopping_cart.pickup')}
          {!readonly && <a href="#" onClick={handleEditPickup}>{t('shopping_cart.edit_pickup')}</a>}
        </div>
        <div className="hstack gap-2">
          <i className="fas fa-map-marker-alt text-primary" />
          {pickupDepot.name}
        </div>
        <div className="hstack gap-4">
          <div className="hstack gap-2">
            <i className="fas fa-calendar-alt text-primary" />
            {formatDate(cart.pickup_time, 'date')}
          </div>
          <div className="hstack gap-2">
            <i className="fas fa-clock text-primary" />
            {formatDate(cart.pickup_time, 'time')}
          </div>
        </div>
      </div>
      <hr className="m-0" />
      <div className="vstack gap-2">
        <div className="hstack gap-3 justify-content-between h6">
          {t('shopping_cart.return')}
          {!readonly && <a href="#" onClick={handleEditReturn}>{t('shopping_cart.edit_return')}</a>}
        </div>
        <div className="hstack gap-2">
          <i className="fas fa-map-marker-alt text-primary" />
          {returnDepot.name}
        </div>
        <div className="hstack gap-4">
          <div className="hstack gap-2">
            <i className="fas fa-calendar-alt text-primary" />
            {formatDate(cart.return_time, 'date')}
          </div>
          <div className="hstack gap-2">
            <i className="fas fa-clock text-primary" />
            {formatDate(cart.return_time, 'time')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Information
