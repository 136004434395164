import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Actions
import { bookProductToCart } from '@client/redux/actions/cart'

// Libraries
import { useDispatch } from 'react-redux'

// Utils
import { track } from '@client/utils/tracking'
import { t } from '@client/i18n/localize'

// Components
import Essentials from '@client/react/components/Essentials'
import { Button, Link, Icon } from '@client/react/components/AddProductToCart'
import AddOptionalAccessories from '@client/react/components/AddOptionalAccessories'
import { notification } from '@client/react/components/Notification'

const AddToCart = ({ id, name, type, label, source, optionalPickupAccessories }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [initializeEssentials, setInitializeEssentials] = useState(false)

  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const components = {
    button: Button,
    icon: Icon,
    link: Link
  }
  const Component = components[type] || Icon

  const hasOptionalPickupAccessories = () => {
    return optionalPickupAccessories && optionalPickupAccessories.length > 0
  }

  const handleVisible = () => {
    visible ? hide() : show()
  }

  const handleClick = ({ accessoryIds=[] }) => {
    setLoading(true)

    dispatch(bookProductToCart({ id, accessoryIds }))
      .then(({ payload }) => {
        if (!payload.cart) { throw('No cart') }

        const ids = [].concat(id)

        if (ids.length === 1 && source !== 'essentials') {
          setInitializeEssentials(true)
        }

        setSuccess(true)
        setLoading(false)
        hide()
        notification({ message: t('shopping_cart.product_added', { product: name }) })

        ids.forEach(id => {
          track('cart.book_product', {
            productId: id,
            cart: payload.cart,
            source
          })
        })

        setTimeout(() => { setSuccess(null) }, 2000)
      })
      .catch(() => {
        notification({ message: t('pages.error_500.page_title'), type: 'danger' })
        setSuccess(false)
        setLoading(false)
        hide()
      })
  }

  return (
    <>
      {hasOptionalPickupAccessories() &&
        <AddOptionalAccessories
          visible={visible}
          onClose={hide}
          onClick={handleClick}
          accessories={optionalPickupAccessories}
          loading={loading}
          success={success}
          productName={name}
        >
          <Component loading={loading} success={success} label={label} onClick={handleVisible} />
        </AddOptionalAccessories>
      }
      {!hasOptionalPickupAccessories() && <Component onClick={handleClick} loading={loading} success={success} label={label} />}

      {<Essentials id={id} name={name} setInitialize={setInitializeEssentials} initialize={initializeEssentials} />}
    </>
  )
}

AddToCart.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.oneOf(['wishlist', 'essentials', 'Related products']),
  optionalPickupAccessories: PropTypes.array,
}

export default AddToCart
