import { fetchAll } from '@client/api/opening_times'

const start = () => {
  return {
    type: 'FETCH_OPENING_TIMES_START'
  }
}

const success = ({ opening_times }) => {
  return {
    type: 'FETCH_OPENING_TIMES_SUCCESS',
    payload: { opening_times }
  }
}

const error = ({ response }) => {
  return {
    type: 'FETCH_OPENING_TIMES_ERROR',
    payload: { response }
  }
}

const fetchOpeningTimes = () => {
  return (dispatch) => {
    dispatch(start())

    return fetchAll()
      .then(
        (response) => { dispatch(success({ opening_times: response.data.opening_times })) },
        (errorData) => { dispatch(error({ response: errorData.response })) }
      )
  }
}

export default fetchOpeningTimes
