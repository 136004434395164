import React from 'react'

// Utils
import { t, translatePeriodLabel } from '@client/i18n/localize'


// Components
import AddToCart from './AddToCart'
import AddToWishlist from './AddToWishlist'
import Price from './Price'
import Tooltip from '@client/react/components/Tooltip'

const PricesListComponent = (prices) => {
  return (
    <div>
      {t('tooltips.multiple_day_prices')}

      <ul>
        <PricesListItemComponent price={prices[0]} />
        <PricesListItemComponent price={prices[1]} />
      </ul>
    </div>
  )
}

const PricesListItemComponent = ({ price }) => {
  return (
    <>
      {price && (
        <li>
          {translatePeriodLabel(price.label)}

          <strong className="ml-1">
            {price.formattedPrice}
          </strong>
        </li>
      )}
    </>
  )
}

const PriceComponent = ({ id, prices }) => {
  const [currentPrice, ...futurePrices] = prices;
  const classNames = ['fas fa-tags item-card__future-prices future-prices__icon animated animated-zoom link-light']

  return (
    <>
      <div className="hstack gap-3">
        <span className="text-gray">{translatePeriodLabel(currentPrice.label || currentPrice.title)}</span>
        <span className="text-medium">{currentPrice.formattedPrice}</span>

        <span className="text-gray text-large">
          <a data-tooltip-id={`show-future-prices-${id}`} className={classNames.join(" ")} role="button" />
        </span>
      </div>

      <Tooltip id={`show-future-prices-${id}`}>
        <PricesListComponent {...futurePrices} />
      </Tooltip>
    </>
  )
}

const ListItem = ({ id, name, path, imageThumbLarge, imageThumb, prices, wishlist, source, optional_pickup_accessories, onClick }) => {
  return (
    <div className="hstack gap-3 py-2 border-bottom">
      <a href={path} onClick={onClick}>
        <img className="img-fluid" alt={name} src={imageThumbLarge} loading="lazy" width="60px" />
      </a>
      <div className="vstack gap-2 justify-content-center">
        <div className="hstack gap-3">
          <a href={path} className="text-dark" onClick={onClick}>{name}</a>
          {wishlist !== false && <AddToWishlist id={id} name={name} image={imageThumb} />}
        </div>
        <div className="hstack justify-content-between">
          <Price id={id} initialPrice={prices} component={PriceComponent} />
          <AddToCart id={id} name={name} type="icon" source={source} optionalPickupAccessories={optional_pickup_accessories} />
        </div>
      </div>
    </div>
  )
}

export default ListItem
