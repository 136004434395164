import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip"
import 'react-tooltip/dist/react-tooltip.css';

const Tooltip = ({ id, children}) => {
  return (
    <ReactTooltip id={id} delayShow={300} place="bottom">
      {children}
    </ReactTooltip>
  )
}

export default Tooltip
