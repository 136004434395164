import axios from '@client/lib/axios'

export const fetchProducts = (params) => {
  return axios({
    method: 'get',
    url: '/api/1/products',
    params: params
  })
}

export const fetchPrices = ({ ids }) => {
  return axios({
    method: 'get',
    url: '/api/1/products/prices',
    params: {
      ids
    }
  })
}

export const fetchEssentials = ({ id }) => {
  return axios({
    method: 'get',
    url: `/api/1/products/${id}/essentials`,
    params: {
      id
    }
  })
}

export const searchProducts = ({ ids }) => {
  return axios({
    method: 'get',
    url: `/api/1/products/search`,
    params: {
      ids
    }
  })
}
