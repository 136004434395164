import { update } from '@client/api/cart'

const start = (params) => {
  return {
    type: 'CART_UPDATE_START',
    payload: params
  }
}

const success = ({ cart }) => {
  return {
    type: 'CART_UPDATE_SUCCESS',
    payload: { cart }
  }
}

const error = ({ response }) => {
  return {
    type: 'CART_UPDATE_ERROR',
    payload: { response }
  }
}

const updateCart = (params) => {
  return (dispatch, getState) => {
    const { cart } = getState()

    dispatch(start({ ...params, token: cart.token }))

    return update({ ...params, token: cart.token })
      .then(
        (response) => { dispatch(success(response.data)) },
        (errorData) => { dispatch(error({ ...params, token: cart.token, response: errorData.response })) }
      )
  }
}

export default updateCart
