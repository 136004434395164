import React from 'react'

// Utils
import { translatePeriodLabel } from '@client/i18n/localize'

// Components
import Price from '@client/react/components/Product/Price'

const PriceComponent = ({ prices }) => {
  const renderItem = ({label, formattedPrice, index}) => {
    const priceClass = index === 0 ? 'h2' : 'text-large text-medium'
    return (
      <div className="d-flex flex-column gap-2">
        <span className="text-gray">{translatePeriodLabel(label)}</span>
        <span className={priceClass}>{formattedPrice}</span>
      </div>
    )
  }

  return (
    <div className="d-flex gap-5">
      {prices.map((price, index) => (
        <React.Fragment key={index}>
          {renderItem({...price, index})}
        </React.Fragment>
      ))}
    </div>
  )
}

const ProductDetailPrice = ({ id, prices }) => {
  return <Price id={id} initialPrice={prices} component={PriceComponent} fetchData />
}

export default ProductDetailPrice
