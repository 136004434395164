// React
import React, { useState } from 'react'

// Components
import Details from './modes'
import Dates from './modes/Dates'
import Depots from './modes/Depots'
import Timeslots from './modes/Timeslots'

// Libraries
import { date, t } from '@client/i18n/localize'

// Shared

const Select = ({ id, value, onClick, icon, iconStyle, type, placeholder, disabled, error }) => {
  const getValue = (mode) => {
    switch (mode) {
      case 'depot':
        return value.name
      case 'date':
        return date(value)
      case 'timeslot':
        return value.label
    }
  }

  return (
    <div id={id} onClick={!disabled ? onClick : null} className={`bc-mobile-picker-select ${disabled ? 'bc-mobile-picker-select--is-disabled' : ''} ${error ? 'bc-mobile-picker-select--has-error' : ''}`}>
      {icon && <i className={`${iconStyle || 'fas'} ${`fa-${icon}`} bc-mobile-picker-select__icon`}></i>}
      {value ? (
        <p className={`bc-mobile-picker-select__value ${icon ? 'bc-mobile-picker-select__value--with-icon' : ''}`}>
          {getValue(type)}
        </p>
      ) : (
        <p
          className={`bc-mobile-picker-select__placeholder ${
            icon ? 'bc-mobile-picker-select__placeholder--with-icon' : ''
          }`}
        >
          {placeholder}
        </p>
      )}
      <i className="fas fa-chevron-down bc-mobile-picker-select__expand-icon"></i>
    </div>
  )
}

const Mobile = ({
  values,
  pickupDepots,
  returnDepots,
  pickupTimeslots,
  returnTimeslots,
  isDayAvailable,
  isTimeslotAvailable,
  handleDepotSelect,
  handleDateSelect,
  handleTimeslotSelect,
  range,
  errors,
  hasError
}) => {
  const [mode, setMode] = useState(null)

  const onBackPress = () => setMode(null)

  const titles = {
    pickupDepot: t('cart_picker.pickup_depot'),
    pickupTimeslot: t('cart_picker.pickup_timeslot'),
    pickupDate: t('cart_picker.pickup_date'),
    returnDepot: t('cart_picker.return_depot'),
    returnTimeslot: t('cart_picker.return_timeslot'),
    returnDate: t('cart_picker.return_date'),
  }

  const modes = (mode) => {
    switch (mode) {
      case 'pickupDepot':
        return (
          <Depots
            depots={pickupDepots}
            errors={errors}
            type="pickup"
            onChange={(depot) => {
              handleDepotSelect('pickup', depot)
              setMode(null)
            }}
          />
        )
      case 'pickupDate':
        return (
          <Dates
            isDayAvailable={isDayAvailable}
            range={range}
            type="pickup"
            errors={errors}
            onChange={(value) => {
              handleDateSelect('pickup', value)
              setMode(null)
            }}
          />
        )
      case 'pickupTimeslot':
        return (
          <Timeslots
            isTimeslotAvailable={isTimeslotAvailable}
            onChange={(value) => {
              handleTimeslotSelect('pickup', value)
              setMode(null)
            }}
            timeslots={pickupTimeslots}
            type="pickup"
            errors={errors}
            values={values}
          />
        )
      case 'returnDepot':
        return (
          <Depots
            depots={returnDepots}
            onChange={(depot) => {
              handleDepotSelect('return', depot)
              setMode(null)
            }}
            errors={errors}
            type="return"
          />
        )
      case 'returnDate':
        return (
          <Dates
            isDayAvailable={isDayAvailable}
            range={range}
            type="return"
            onChange={(value) => {
              handleDateSelect('return', value)
              setMode(null)
            }}
            errors={errors}
          />
        )
      case 'returnTimeslot':
        return (
          <Timeslots
            isTimeslotAvailable={isTimeslotAvailable}
            onChange={(value) => {
              handleTimeslotSelect('return', value)
              setMode(null)
            }}
            timeslots={returnTimeslots}
            type="return"
            errors={errors}
          />
        )
    }
  }

  return (
    <div className="bc-mobile-picker">
      <div className="bc-mobile-picker__category-block">
        <p className="bc-mobile-picker__category">{t('cart_picker.pickup')}</p>
        <Select
          id="pickup_depot"
          type="depot"
          icon="map-marker-alt"
          iconStyle="fas"
          value={values.pickupDepot}
          placeholder={t('cart_picker.pickup_depot')}
          onClick={() => setMode('pickupDepot')}
          error={hasError(errors, 'pickup', 'depot')}
        />
        <div className="bc-mobile-picker__select-group">
          <Select
            id="pickup_date"
            value={values.pickupDate}
            icon="calendar-alt"
            placeholder={t('cart_picker.pickup_date')}
            onClick={() => setMode('pickupDate')}
            type="date"
            disabled={!values.pickupDepot}
            error={hasError(errors, 'pickup', 'date')}
          />
          <Select
            id="pickup_timeslot"
            value={values.pickupTimeslot}
            placeholder={t('cart_picker.pickup_timeslot')}
            onClick={() => setMode('pickupTimeslot')}
            type="timeslot"
            icon="clock"
            disabled={!values.pickupDate}
            error={hasError(errors, 'pickup', 'timeslot')}
          />
        </div>
      </div>
      <div className="bc-mobile-picker__category-block">
        <p className="bc-mobile-picker__category">{t('cart_picker.return')}</p>
        <Select
          id="return_depot"
          type="depot"
          icon="map-marker-alt"
          iconStyle="fas"
          value={values.returnDepot}
          placeholder={t('cart_picker.return_depot')}
          onClick={() => setMode('returnDepot')}
          error={hasError(errors, 'return', 'depot')}
        />
        <div className="bc-mobile-picker__select-group">
          <Select
            id="return_date"
            value={values.returnDate}
            icon="calendar-alt"
            placeholder={t('cart_picker.return_date')}
            onClick={() => setMode('returnDate')}
            type="date"
            disabled={!values.returnDepot}
            error={hasError(errors, 'return', 'date')}
          />
          <Select
            id="return_timeslot"
            value={values.returnTimeslot}
            placeholder={t('cart_picker.return_timeslot')}
            onClick={() => setMode('returnTimeslot')}
            type="timeslot"
            icon="clock"
            iconStyle="fas"
            disabled={!values.returnDate}
            error={hasError(errors, 'return', 'timeslot')}
          />
        </div>
      </div>
      {mode && (
        <Details title={titles[mode]} onBackPress={onBackPress}>
          {modes(mode)}
        </Details>
      )}
    </div>
  )
}

export default Mobile
