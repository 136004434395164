// React
import React from 'react'

// Components

// Libraries

// Shared

const Timeslots = ({ onChange, isTimeslotAvailable, timeslots, type, values }) => {
  const handleChange = (timeslot) => {
    if (isTimeslotAvailable(timeslot, type, type === 'pickup')) {
      onChange(timeslot)
    }
  }

  return (
    <ul className="bc-mobile-picker-timeslots">
      {timeslots.map((timeslot) => (
        <li key={timeslot.id}
          className={`bc-mobile-picker-timeslot ${
            !isTimeslotAvailable(timeslot, type, type === 'pickup') ? 'bc-mobile-picker-timeslot--disabled' : ''
          }`}
          onClick={() => handleChange(timeslot)}
        >
          <p className="bc-mobile-picker-timeslot__label">{timeslot.label}</p>
        </li>
      ))}
    </ul>
  )
}

export default Timeslots
