import React, { useState } from 'react'

// Utils
import { t } from '@client/i18n/localize'

const VoteOption = ({ id, title, selected, onClick }) => {
  return (
    <label className={`hstack gap-3 poll-option ${selected ? 'poll-option--active' : ''}`} onClick={onClick.bind(null, id)}>
      <input className="d-none" name="poll-option" type="radio" />
      <i className={`text-large fas ${selected ? 'fa-circle-check text-primary' : 'fa-circle'}`} />
      <span className="poll-option__text">{title}</span>
    </label>
  )
}

const Vote = ({ options, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState(null)

  const handleClickOption = (id) => {
    setSelectedOption(id)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(selectedOption)
  }

  return (
    <>
      {options.map((option) => (
        <VoteOption
          {...option}
          key={option.id}
          onClick={handleClickOption}
          selected={option.id === selectedOption}
        />
      ))}

      <div className="d-flex justify-content-end">
        <input className={`btn btn-primary ${selectedOption ? '' : 'disabled'}`} type="submit" value={t('poll.vote')} onClick={handleSubmit} />
      </div>
    </>
  )
}

export default Vote
