import React, { forwardRef } from 'react'

// Components
import Icon from './Icon'

const Button = forwardRef(({ onClick, loading, success, label }, ref) => {
  const isDisabled = loading || success
  const buttonClasses = `btn btn-primary ${isDisabled ? 'disabled' : ''} ${label ? 'w-100' : ''}`

  let buttonContent;
  if (isDisabled) {
    buttonContent = <Icon loading={loading} success={success} />;
  } else if (label) {
    buttonContent = (
      <>
        <i className="far fa-shopping-cart mr-1" />
        {label}
      </>
    );
  } else {
    buttonContent = <Icon />
  }

  return (
    <button
      ref={ref}
      className={buttonClasses}
      onClick={!isDisabled ? onClick : undefined}
      disabled={isDisabled}
    >
      {buttonContent}
    </button>
  );
});

export default Button
