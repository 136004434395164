import axios from '@client/lib/axios'

export const fetchPoll = (params) => {
  return axios({
    method: 'get',
    url: '/api/1/poll'
  })
}

export const votePoll = ({ option }) => {
  return axios({
    method: 'post',
    url: '/api/1/poll/vote',
    params: {
      option
    }
  })
}
