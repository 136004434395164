import React from 'react'

// Utils
import { t } from '@client/i18n/localize'

// Components
import Modal from '@client/react/components/Modal'

const Login = ({ product }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>{t('wishlists.add_products')}</Modal.Title>
        <Modal.SubTitle>{product.name}</Modal.SubTitle>
      </Modal.Header>
      <Modal.Body>
        <div className="hstack gap-3">
          <div className="d-none d-sm-block col-auto">
            <img src={product.image} alt="product-image" className="img-fluid" width="125px" loading="lazy" />
          </div>
          <div className="col">
            <div className="alert alert-warning">{t('wishlists.login')}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <a href="/users/sign_in" className="btn btn-primary">{t('login')}</a>
      </Modal.Footer>
    </>
  )
}

export default Login
