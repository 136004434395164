import { i18n } from '../../../../app/javascript/packs/localized-i18n'
import moment from 'moment-timezone'

const i18nMoment = (opts) => {
  moment.locale(i18n.locale)
  moment.tz.setDefault('Europe/Amsterdam')
  return moment(opts)
}

export default i18nMoment
