import ReactRailsUJS from 'react_ujs'
import reduxComponents from './redux_components'

export default (store) => {
  // Support for dynamically requiring components with `react-rails`
  const componentRequireContext = require.context('client/javascripts/react/components', true)
  ReactRailsUJS.useContext(componentRequireContext)

  // Wrap react components with a redux `<Provider>`
  reduxComponents(ReactRailsUJS, store)
}
