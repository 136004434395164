import React from 'react'

// Libraries
import { useSelector } from 'react-redux'

// Components
import Modal from '@client/react/components/Modal'

// Utils
import { t } from '@client/i18n/localize'

const Insurance = ({ onClose, show }) => {
  const company = useSelector((state) => state.company)

  const paragraphs = t('insurance.body', { company_name: company.name_legal || company.name }).split('\n\n')

  return (
    <Modal onClose={onClose} show={show}>
      <Modal.Header border>
        <Modal.Title>{t('insurance.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="insurance__body">
          {paragraphs.map((p, i) => <p key={i} className="mb-3">{p}</p>)}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Insurance
