const close = () => {
  return {
    type: 'CLOSE_PICKER',
    payload: {}
  }
}

const closePicker = () => {
  return (dispatch) => {
    return dispatch(close())
  }
}

export default closePicker
