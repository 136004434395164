// React
import React from 'react'

// Components

// Libraries

// Shared

const selectPlaceholder = ({ icon, value }) => (
  <>
    <i className={`fas fa-${icon}`}></i> <span className="ml-2">{value}</span>
  </>
)

export default selectPlaceholder
