import React, { useState, useEffect } from 'react'

// Actions
import { fetchProductPrices } from '@client/redux/actions/products'

// Libraries
import { useSelector, useDispatch } from 'react-redux'

const Price = ({ id, component, initialPrice, fetchData }) => {
  const dispatch = useDispatch()
  const pricesFromState = useSelector(state => (state.prices || {})[id])
  const cart = useSelector(state => state.cart)

  const [isMounted, setIsMounted] = useState(false)
  const [prices, setPrices] = useState(initialPrice)

  const Component = component

  useEffect(() => {
    setIsMounted(true)
  }, [])

  // Use two-step render to render an updated version with prices
  useEffect(() => {
    if (isMounted && pricesFromState) {
      setPrices(pricesFromState)
    }
  }, [isMounted, pricesFromState])

  // Fetch price data if `fetchData` is true
  useEffect(() => {
    if (fetchData) {
      dispatch(fetchProductPrices({ ids: id }))
    }
  }, [cart.pickup_time, cart.return_time])

  return (
    <Component id={id} prices={prices} />
  )
}

export default Price
