import React, { useState } from 'react'

// API
import { fetchProducts } from '@client/api/products'

// Utils
import { t } from '@client/i18n/localize'
import isEmpty from 'lodash/isEmpty'

// Components
import ProductFilter from './ProductFilter'
import ProductList from './ProductList'
import ProductFilterButton from './ProductFilter/Button'
import NoResults from './ProductFilter/NoResults'

const getDynamicProps = (component) => {
  const props = global.dynamicComponentProps || {}

  return props[component] || {}
}

const ProductResults = (props) => {
  const { products, filterGroups, currentFilters, filterCounters, currentParams, productClassName } = { ...props, ...getDynamicProps('ProductResults')}
  const [loading, setLoading] = useState(false)
  const [currentProducts, setCurrentProducts] = useState(products)
  const [params, setParams] = useState({ ...currentParams, per: 24, page: 1 })
  const [hasNextPage, setHasNextPage] = useState(currentProducts.length < params.per ? false : true)

  const hasResults = currentProducts.length > 0 || !isEmpty(currentFilters)

  const handleNextPage = (e) => {
    if (e) e.preventDefault()

    const newParams = { ...params, page: params.page + 1 }

    setParams(newParams)
    setLoading(true)

    fetchProducts(newParams).then((response) => {
      const newProducts = response.data.products

      if (newProducts.length < params.per) {
        setHasNextPage(false)
      }

      setCurrentProducts([...currentProducts, ...newProducts])
      setLoading(false)
    })
  }

  return (
    <div className="row">
      {hasResults && <>
        <div className="col-12 d-md-none">
          <ProductFilterButton
            filterGroups={filterGroups}
            current={currentFilters}
            counters={filterCounters}
          />
        </div>
        <div className="d-none d-md-block col-12">
          <ProductFilter
            filterGroups={filterGroups}
            current={currentFilters}
            counters={filterCounters}
            view="tags"
          />
        </div>
        <div className="col-12 col-md-3 d-none d-md-block">
          <ProductFilter
            filterGroups={filterGroups}
            current={currentFilters}
            counters={filterCounters}
          />
        </div>
        <div className="col col-md-9 products-wrap">
          <div className="row gy-3">
            <ProductList products={currentProducts} productClassName={productClassName} />

            {currentProducts.length === 0 && <NoResults />}

            {hasNextPage &&
              <div className="col-12">
                <a className="btn btn-secondary" href="#" onClick={handleNextPage}>{t('product_results.show_more_products')}</a>
              </div>
            }
          </div>
        </div>
      </>}
      {!hasResults && <NoResults />}
    </div>
  )
}

export default ProductResults
