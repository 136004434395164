import React from 'react'

const ResultOption = ({ title, percentage }) => {
  return (
    <div className="mt-3">
      <div className='hstack gap-2 mb-2'>
        <span className="text-medium">{title}</span>
        <span className="text-gray">{percentage}%</span>
      </div>
      <div className="poll-result-bar">
        <div className="poll-result-bar__fill" style={{ width: `${percentage}%` }} />
      </div>
    </div>
  )
}

const Results = ({ options }) => {
  const votes = options.reduce((a, option) => (option.poll_votes_count || 0) + a, 0)

  const percentage = (option) => {
    const count = (option.poll_votes_count || 0)
    return ((count / votes) * 100).toFixed()
  }

  return (
    <div className="poll-results">
      {options.map((option) => (
        <ResultOption
          key={option.id}
          title={option.title}
          percentage={percentage(option)}
        />
      ))}
    </div>
  )
}

export default Results
