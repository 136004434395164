import { fetchPrices } from '@client/api/products'

const start = ({ ids }) => {
  return {
    type: 'FETCH_PRODUCT_PRICES_START',
    payload: { ids }
  }
}

const success = (payload) => {
  return {
    type: 'FETCH_PRODUCT_PRICES_SUCCESS',
    payload,
  }
}

const error = ({ response }) => {
  return {
    type: 'FETCH_PRODUCT_PRICES_ERROR',
    payload: { response }
  }
}

const fetchProductPrices = ({ ids }) => {
  return (dispatch) => {
    dispatch(start({ ids }))

    return fetchPrices({ ids })
      .then(
        (response) => dispatch(success(response.data)),
        (errorData) => dispatch(error({ ids, response: errorData.response }))
      )
  }
}

export default fetchProductPrices
