import React, { useState, useEffect } from 'react'

// Libraries
import { useDispatch } from 'react-redux'
import map from 'lodash/map'

// Actions
import { fetchProductPrices } from '@client/redux/actions/products'

// Utils
import { t } from '@client/i18n/localize'

// Components
import AddToCart from '@client/react/components/Product/AddToCart'

const ProductSum = ({ ids, name, source }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    dispatch(fetchProductPrices({ ids })).then((action) => {
      setLoading(false)
      setData(action.payload)
    })
  }, [ids.sort().join('-')])

  if (loading) return null

  return (
    <div className="bg-light shadow-sm border rounded-sm p-4">
      <div className="vstack gap-2 mb-4">
        <div className="hstack justify-content-between">
          <span>{t('prices.subtotal')}</span>
          <span>{data.formattedSubtotal}</span>
        </div>
        {map(data.vat, (amount, percentage) => (
          <div key={amount} className="hstack justify-content-between">
            <span>{t('prices.tax_amount', { percentage: parseInt(percentage) })}</span>
            <span>{data.formattedVatAmount}</span>
          </div>
        ))}
        <hr className="m-0" />
        <div className="hstack justify-content-between text-medium">
          <span>{t('prices.total')}</span>
          <span>{data.formattedTotal}</span>
        </div>
      </div>
      <AddToCart id={ids} type="button" label={t('product_sum.add_all_to_cart')} name={name} source={source} />
    </div>
  )
}

export default ProductSum
