const trackingData = (type, data) => {
  switch (type) {
    case 'cart': {
      return {
        currency: 'EUR',
        value: data.total,
        content_ids: data.products.map((product) => product.id),
        num_items: data.products.length,
        ...trackingData('customer')
      }
    }
    case 'customer': {
      const customerData = window.customerData

      return {
        customer_segment: customerData.segment,
        customer_category: customerData.category
      }
    }
    case 'book_product': {
      return {
        currency: 'EUR',
        value: (data.line.total_in_cents / 100.0) / data.line.qty,
        content_name: data.line.product.name,
        content_type: 'product',
        content_ids: [data.line.product.id],
        ...trackingData('customer')
      }
    }
  }
}

const fakeTracker = (...args) => {
  console.log('fbq', ...args)
}

const tracker = () => {
  return window.fbq && typeof window.fbq === 'function' ? window.fbq : fakeTracker
}

const track = (event, data) => {
  const fbq = tracker()

  switch (event) {
    case 'page.visit': {
      fbq('track', 'PageView')

      break
    }
    case 'cart.book_product': {
      const line = data.cart.carts_products.find((line) => line.product_id === data.productId)

      fbq('track', 'AddToCart', trackingData('book_product', { ...data, line }))

      break
    }
    case 'checkout.cart': {
      fbq('track', 'InitiateCheckout', trackingData('cart', data))

      break
    }
    case 'checkout.confirmation': {
      fbq('track', 'Purchase', trackingData('cart', data))

      break
    }

  }
}

export default track
