import React, { useEffect } from 'react'

// Libraries
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { fetchWishlist } from '@client/redux/actions/wishlists'

// Components
import ProductList from '@client/react/components/ProductList'
import ProductSum from '@client/react/components/ProductSum'

const Wishlist = ({ id }) => {
  const dispatch = useDispatch()
  const wishlist = useSelector(state => state.wishlists.find((wishlist) => wishlist.id === id))

  useEffect(() => {
    dispatch(fetchWishlist({ id }))
  }, [id])

  if (!wishlist) return null

  const products = wishlist.products

  return (
    <>
      <div className="col-12 col-lg">
        <div className="row gy-3">
          <ProductList products={products} productClassName="col-12" source="wishlist" type="list" />
        </div>
      </div>
      <div className="col-12 col-lg-auto">
        <ProductSum ids={products.map(p => p.id)} name={wishlist.name} source="wishlist" />
      </div>
    </>
  )
}

export default Wishlist
