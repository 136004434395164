import React from 'react'

// Components
import AccessoryList from './AccessoryList'
import Button from './Button'
import Modal from '@client/react/components/Modal'

// Utils
import { t } from '@client/i18n/localize'

const Mobile = ({ productName, accessories, isSelected, onChange, onClick, selected, loading, success, visible, onClickOutside, type, children }) => {

  const renderButtons = () => {
    if (type === 'edit') {
      return (
        <Button
          className="btn btn-primary"
          onClick={onClick}
          label={t('shopping_cart.change_accessories')}
          loading={loading}
          success={success} />
      )
    } else {
      return (
        <div className="d-flex gap-2">
          <Button
            className="btn btn-link"
            onClick={onClick}
            disabled={selected.length > 0}
            label={t('skip')}
            loading={loading && selected.length === 0}
            success={success && selected.length === 0} />
          <Button
            className="btn btn-primary"
            onClick={onClick}
            disabled={selected.length === 0}
            label={t('add')}
            loading={loading && selected.length > 0}
            success={success && selected.length > 0} />
        </div>
      )
    }
  }

  return (
    <>
      <Modal show={visible} onClickOutside={onClickOutside} onClose={type === 'edit' && onClickOutside}>
        <Modal.Header>
          <Modal.Title>{t('pickup_accessories.title')}</Modal.Title>
          <Modal.SubTitle>{t('pickup_accessories.sub_title', { product: productName })}</Modal.SubTitle>
        </Modal.Header>
        <Modal.Body>
          <AccessoryList accessories={accessories} isSelected={isSelected} onChange={onChange} />
        </Modal.Body>
        <Modal.Footer>
          {renderButtons()}
        </Modal.Footer>
      </Modal>
      {children}
    </>
  )
}

export default Mobile
