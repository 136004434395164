import React, { useState, useEffect } from 'react'

// Libraries
import { values, flatten } from 'lodash'

// Utils
import { toggleFilter, filtersPath } from './utils'

// Components
import FilterList from './List'
import FilterTags from './Tags'

const ProductFilter = ({ filterGroups, current, counters, onChange, navigate = true, view = 'list' }) => {
  const [isMounted, setIsMounted] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({})
  const selectedCount = flatten(values(selectedFilters)).length
  const [currentCounters, setCurrentCounters] = useState(null)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  // Use two-step render to render an updated version
  useEffect(() => {
    if (isMounted) {
      setCurrentCounters(counters)
      setSelectedFilters({ ...current })
    }
  }, [isMounted])

  const handleToggleFilter = (filterSlug, filterGroupSlug) => {
    const newSelectedFilters = toggleFilter(filterSlug, filterGroupSlug, selectedFilters)
    const path = `${filtersPath(newSelectedFilters)}${window.location.search}`

    setSelectedFilters(newSelectedFilters)

    if (navigate) {
      window.restoreScrollPosition = true
      Turbolinks.visit(path)
    } else {
      // On change is implemented, this means that the behavior of selecting filters is handled elsewhere
      onChange(newSelectedFilters, path)
    }
  }

  const View = {
    list: FilterList,
    tags: FilterTags
  }[view]

  return (
    <View
      selectedCount={selectedCount}
      filterGroups={filterGroups}
      selectedFilters={selectedFilters}
      handleToggleFilter={handleToggleFilter}
      counters={currentCounters}
      navigate={navigate}
    />
  )
}

export default ProductFilter
