import React from 'react'

// Libraries
import { isEmpty, map, flatten } from 'lodash'

// Utils
import { isFilterPresent } from './utils'

const Tags = ({ filterGroups, selectedFilters, handleToggleFilter }) => {
  const renderedFilters = map(filterGroups, (filterGroup) => {
    return filterGroup.filters.map((filter) => {
      const selected = isFilterPresent(filter.slug, filterGroup.slug, selectedFilters)

      if (selected) {
        return (
          <div key={filter.id} className="inline-filter__item inline-filter__item--small gap-1" onClick={() => handleToggleFilter(filter.slug, filterGroup.slug)}>
            {filter.name}
            <i className="fas fa-times"></i>
          </div>
        )
      }
    }).filter((item) => item)
  }).filter((item) => item)

  return (
    <>
      {!isEmpty(flatten(renderedFilters)) && <div className="inline-filter">
        {flatten(renderedFilters)}
      </div>}
    </>
  )
}

export default Tags
