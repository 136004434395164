import React, { useState, useEffect } from 'react'

// API
import { fetchEssentials } from '@client/api/products'

// Libraries
import sortBy from 'lodash/sortBy'

// Utils
import { t } from '@client/i18n/localize'

// Components
import ProductList from './ProductList'
import Modal from '@client/react/components/Modal'

const Category = ({ name, products }) => {
  return (
    <>
      <p className="essentials__category-title">{name}</p>
      <div className="row">

        <ProductList
          products={products}
          type="list"
          productClassName="essential-product col-12 mb-3"
          wishlist={false}
          source="essentials"
        />
      </div>
    </>
  )
}

const Essentials = ({ id, name, initialize, setInitialize }) => {
  const [essentials, setEssentials] = useState(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!initialize) return

    fetchEssentials({ id }).then(({ data }) => {
      const products = data.products
      const categories = data.meta.categories

      if (products.length === 0) {
        return
      }

      // Group products by category name
      const categoryData = categories.map((category) => {
        return {
          ...category,
          products: products.filter((product) => product.essential_category_id === category.id)
        }
      })

      // Sort by product count
      const sortedData = sortBy(categoryData, (data) => -data.products.length)

      setEssentials(sortedData)
      setShow(true)
    })
  }, [initialize])

  const handleClose = (e) => {
    e && e.preventDefault()
    setShow(false)
    setInitialize(false)
  }

  return (
    <Modal onClose={handleClose} show={show}>
      <Modal.Header>
        <Modal.Title>{t('essentials.for_product', { name })}</Modal.Title>
        <Modal.SubTitle>{t('essentials.make_complete')}</Modal.SubTitle>
      </Modal.Header>
      <Modal.Body>
        {essentials?.map((data) => (
          <Category key={data.id} name={data.name} products={data.products} />
        ))}
      </Modal.Body>
    </Modal>
  )
}

export default Essentials
