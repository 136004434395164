import React, { useState } from 'react'

// Libraries
import { values, flatten } from 'lodash'

// Utils
import { t } from '@client/i18n/localize'
import { productsPath } from '@client/utils'

// Components
import ProductFilter from './'
import Modal from '@client/react/components/Modal'

const Button = ({ filterGroups, current, counters }) => {
  const [show, setShow] = useState(false)
  const [filterPath, setFilterPath] = useState(productsPath())

  const handleToggleShow = (e) => {
    e && e.preventDefault()
    setShow(!show)
  }

  const handleChangeFilters = (filters, path) => {
    setFilterPath(path)
  }

  const handleApplyFilters = (e) => {
    e.preventDefault()
    Turbolinks.visit(filterPath)
  }

  const selectedCount = flatten(values(current)).length

  return (
    <>
      <button className="btn btn-primary w-100 d-flex align-items-center justify-content-between mb-3" onClick={handleToggleShow}>
        {t('product_results.filters')} ({selectedCount}) <i className="fas fa-sliders-simple" />
      </button>
      {show &&
        <Modal onClose={handleToggleShow} show={show}>
          <Modal.Body>
            <ProductFilter
              filterGroups={filterGroups}
              current={current}
              counters={counters}
              onChange={handleChangeFilters}
              navigate={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <a href="#" className="btn btn-primary" onClick={handleApplyFilters}>{t('product_results.apply_filters')}</a>
          </Modal.Footer>
        </Modal>
      }
    </>
  )
}

export default Button
