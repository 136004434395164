import React, { useRef } from 'react'

const Popover = ({ children, title, subtitle, onClose, fixedWidth }) => {
  const backgroundNode = useRef(null)
  const modifiers = []

  if (fixedWidth) modifiers.push('fixed-width')

  const handleClick = (e) => {
    if (e.target === backgroundNode.current) {
      onClose()
    }
  }

  return (
    <div className={`bc-popover ${modifiers.map(m => `bc-popover--${m}`)}`} onClick={handleClick} ref={backgroundNode}>
      <div className="bc-popover-box">
        {onClose && <i className="fas fa-times bc-popover__close" onClick={onClose} />}
        <div className="bc-popover-content">
          {title && <p className="bc-popover__title">{title}</p>}
          {(title || subtitle) && <hr className="bc-popover__hr" />}
          {children}
        </div>
      </div>
    </div>
  )
}

export default Popover
