import React, { useState, useEffect } from 'react'

// Libraries
import { useSelector } from 'react-redux'

// Utils
import { t } from '@client/i18n/localize'

// Components
import AddProductToWishlist from '@client/react/components/AddProductToWishlist'
import Tooltip from '@client/react/components/Tooltip'

const AddToWishlist = (props) => {
  const wishlists = useSelector((state) => state.wishlists)
  const addedFromState = !!wishlists.find((wishlist) => wishlist.product_ids.indexOf(props.id) >= 0)

  const [show, setShow] = useState(null)
  const [added, setAdded] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const classNames = ['fa-heart add-to-wishlist animated animated-heartbeat link-light']

  // Selected state
  classNames.push(added ? 'fas text-danger' : 'fas')

  if (props.className) classNames.push(props.className)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  // Use two-step render to render an updated version
  useEffect(() => {
    if (isMounted) {
      setAdded(addedFromState)
    }
  }, [isMounted, addedFromState])

  const handleClick = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <AddProductToWishlist product={props} onClose={handleClose} show={show} />
      {props.type !== 'label' && (
        <>
          <a
            data-tooltip-content={t('tooltips.add_to_favorites')}
            data-tooltip-id="add-to-favourite"
            className={classNames.join(' ')}
            onClick={handleClick}
          />
          <Tooltip id="add-to-favourite" />
        </>
      )}
      {props.type === 'label' && (
        <button className="btn btn-secondary ${added ? '' : 'inactive'} w-100" onClick={handleClick}>
          <i className="far fa-heart" />
        </button>
      )}
    </>
  )
}

export default AddToWishlist
