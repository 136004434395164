// React
import React from 'react'

// Components

// Libraries
import Tooltip from '@client/react/components/Tooltip'

// Shared
import isMobile from '@client/utils/isMobile'
import { t, calendarDate } from '@client/i18n/localize'
import { fromAsTime, tillAsTime } from '@client/utils/timeslots'

const formatCalendarDate = (date, timeslot, type, format = false) => {
  const datetime = (type === 'return') ? tillAsTime(date, timeslot) : fromAsTime(date, timeslot)
  return calendarDate(datetime, format)
}

const Action = ({ date, depot, type, timeslot, onClick, label }) => {
  const noData = !date || !depot || !timeslot

  if (isMobile()) {
    return (
      <div className="bc-date-select__action" onClick={onClick}>
        {noData ? (
          label
        ) : (
          <>
            <div className="text-small">{depot.name}</div>
            <div>{formatCalendarDate(date, timeslot, type, true)}</div>
          </>
        )}
      </div>
    )
  } else {
    return (
      <div className="bc-date-select__action"
        onClick={onClick}
        data-tooltip-id="period-picker"
        data-tooltip-content={label}
      >
        {noData ? (
          label
        ) : (
            <div className="hstack gap-2">
              <div>{depot.name}</div>
              <div>|</div>
              <div>{formatCalendarDate(date, timeslot, type)}</div>
              <Tooltip id="period-picker" />
            </div>
          )}
      </div>
    )
  }
}

const Intro = ({ values, onClick, errors }) => {
  const { pickupTime, returnTime, pickupDepot, returnDepot, pickupTimeslot, returnTimeslot } = values
  const noData = !pickupTime || !returnTime || !pickupDepot || !returnDepot
  const hasErrors = errors.length !== 0

  if (noData) {
    if (isMobile()) {
      return (
        <div className="bc-date-select__intro" onClick={onClick}>
          <span>{t('cart_picker.choose_period_short')}</span>
        </div>
      )
    } else {
      return (
        <div className="bc-date-select__intro">
          {t('cart_picker.price_duration')} <button className="btn btn-intro" onClick={onClick}>{t('cart_picker.choose_period_short')}</button>
        </div>
      )
    }
  }

  const wrapperClasses = ['hstack justify-content-center bc-date-select-wrapper']
  if (hasErrors) wrapperClasses.push('bc-date-select-wrapper--error')

  return (
    <div className={wrapperClasses.join(' ')}>
      <Action
        label={t('cart_picker.tooltips.pickup')}
        date={pickupTime}
        depot={pickupDepot}
        type="pickup"
        timeslot={pickupTimeslot}
        onClick={onClick}
      />
      <i className="fas fa-long-arrow-right bc-date-select__icon" />
      <Action
        label={t('cart_picker.tooltips.return')}
        date={returnTime}
        depot={returnDepot}
        type="return"
        timeslot={returnTimeslot}
        onClick={onClick}
      />
    </div>
  )
}

export default Intro
