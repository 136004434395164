import React, { useState, useEffect } from 'react'

// Actions
import { fetchProductPrices } from '@client/redux/actions/products'

// Libraries
import { useDispatch, useSelector } from 'react-redux'
import map from 'lodash/map'
import difference from 'lodash/difference'
import { track } from '@client/utils/tracking'

// Components
import Product from './Product'

const ProductList = ({ products, productClassName, component, type, wishlist, source, trackImpression = true }) => {
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)
  const [ids, setIds] = useState([])

  const handleClickProduct = (product) => {
    // Remove possible scroll-lock class from Modal
    document.querySelector('html').classList.remove('scroll-lock')
    track('product.click', { product, source })
  }

  // Update prices for new products
  useEffect(() => {
    const allIds = map(products, (product) => product.id)
    const newIds = ids.length === 0 ? allIds : difference(allIds, ids)

    if (newIds.length > 0) {
      setIds(allIds)
      dispatch(fetchProductPrices({ ids: newIds }))
    }
  }, [products])

  // Track impressions
  useEffect(() => {
    if (trackImpression) track('product.impression', { products, source })
  }, [products])

  // Update prices on cart update
  useEffect(() => {
    if (ids.length > 0) {
      dispatch(fetchProductPrices({ ids }))
    }
  }, [cart.pickup_time, cart.return_time])

  const Component = component || Product
  const renderProduct = (product) => (
    <Component
      {...product}
      key={product.id}
      type={type}
      wishlist={wishlist}
      source={source}
      onClick={handleClickProduct.bind(null, product)}
    />
  )

  if (component) {
    return products.map(renderProduct)
  } else {
    return products.map(product => (
      <div key={product.id} className={productClassName}>
        {renderProduct(product)}
      </div>
    ))
  }
}

export default ProductList
