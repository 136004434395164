import React from 'react'

// Libraries
import { useSelector } from 'react-redux'

// Components
import Modal from '@client/react/components/Modal'

// Utils
import { t } from '@client/i18n/localize'
import { url } from '@client/utils'

const Delivery = ({ onClose, show }) => {
  const company = useSelector(state => state.company)

  const faq_url = url(`${t('routes.faq')}#faq-10`)

  const faq_link = `<a href=${faq_url} data-turbolinks="false" target="_blank">${t('delivery_service.faq_link_text')}</a>`
  const paragraphs = t('delivery_service.body', { faq_link: faq_link, price: company.delivery_service_price_from }).split('\n\n')

  return (
    <Modal onClose={onClose} show={show}>
      <Modal.Header border>
        <Modal.Title>{t('delivery_service.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="insurance__body">
          {paragraphs.map((p, i) => <p key={i} className="mb-3" dangerouslySetInnerHTML={{__html: p}} />)}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Delivery
