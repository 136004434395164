import React, { useState, useEffect, useRef } from 'react'

// Libraries
import { useDispatch, useSelector } from "react-redux"
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'

// Utils
import { t } from '@client/i18n/localize'
import { track } from '@client/utils/tracking'

// Actions
import { applyCouponOnCart, removeCouponFromCart } from '@client/redux/actions/cart'

// Components
import Loader from '@client/react/components/Loader'

const Information = () => {
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const coupon = cart.coupon || {}
  const inputNode = useRef(null)

  const [show, setShow] = useState(coupon.code ? true : false)
  const [code, setCode] = useState(coupon.code || '')
  const [status, setStatus] = useState(null)
  const hasCoupon = !isEmpty(trim(coupon.code))

  const handleShow = (e) => {
    e.preventDefault()

    setShow(true)
  }

  const handleChangeCoupon = (e) => {
    setCode(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setStatus('loading')

    if (isEmpty(trim(code))) {
      dispatch(removeCouponFromCart()).then(() => {
        setShow(false)
        setStatus(null)
      }).then(() => {
        track('cart.remove_coupon', { code: coupon.code })
      })
    } else {
      dispatch(applyCouponOnCart({ coupon: code })).then(({ type }) => {
        if (type.endsWith('_ERROR')) {
          setStatus('error')
        } else {
          setStatus('success')
          track('cart.apply_coupon', { code })
        }
      })
    }
  }

  useEffect(() => {
    if (show) {
      inputNode.current.focus()
    }
  }, [show])

  useEffect(() => {
    if (hasCoupon) {
      setShow(true)
      setCode(coupon.code)
    }
  }, [coupon.code])

  return (
    <>
      {!show && (
        <a className="promo-toggle link-dark" onClick={handleShow} href="#">
          {t('shopping_cart.have_a_coupon')}
        </a>
      )}
      {show && (
        <div id="promo">
          <form className="d-flex gap-2" onSubmit={handleSubmit}>
            <div className="relative w-100">
              <input
                type="text"
                name="coupon"
                placeholder={t('shopping_cart.enter_coupon')}
                className={`form-control text-uppercase coupon-input ${status === 'error' ? 'is-invalid' : ''}`}
                onChange={handleChangeCoupon}
                value={code}
                ref={inputNode}
              />
              {status === 'loading' && <Loader className="item-promo__status" />}
              {status === 'success' && <i className="fas fa-check item-promo__status" />}
            </div>
            <button className="btn btn-primary">
              <i className="fas fa-plus" />
            </button>
          </form>
        </div>
      )}
    </>
  )
}

export default Information
