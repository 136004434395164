import React, { useState, useEffect } from 'react'

// Components
import Modal from '@client/react/components/Modal'
import SelectList from './SelectList'
import NewList from './NewList'
import Login from './Login'

// Libraries
import { useSelector } from 'react-redux'

const AddProductToWishlist = ({ product, onClose, show }) => {
  const user = useSelector(state => state.user)
  const [step, setStep] = useState('selectList')

  useEffect(() => {
    setStep(user?.id ? 'selectList' : 'login')
  }, [user])

  const handleNewList = () => {
    setStep('newList')
  }

  const handleBack = () => {
    setStep('selectList')
  }

  return (
    <Modal onClose={onClose} show={show}>
      {step === 'login' && <Login product={product} />}
      {step === 'selectList' && <SelectList product={product} onClose={onClose} onNewList={handleNewList} />}
      {step === 'newList' && <NewList product={product} onClose={onClose} onBack={handleBack} />}
    </Modal>
  )
}

export default AddProductToWishlist
