// React
import React, { useState, useEffect } from 'react'

// Libraries
import { useDispatch, useSelector } from 'react-redux'
import moment from '@client/i18n/moment'
import isEqual from 'lodash/isEqual'

// Utils
import { getTimeslot, filterTimeslots, fromAsTime, tillAsTime } from '@client/utils/timeslots'
import Validation from './Validation'

// Actions
import { updateCart } from '@client/redux/actions/cart'
import { fetchOpeningTimes } from '@client/redux/actions/openingTimes'
import { fetchTimeslots } from '@client/redux/actions/timeslots'
import { fetchDepots } from '@client/redux/actions/depots'

// Components
import LocationDate from '@client/react/components/Picker'
import ModalWrapper from '@client/react/components/Cart/ModalWrapper'

const Picker = ({ intro = true, ...props }) => {
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)
  const timeslots = useSelector(state => state.timeslots)
  const depots = useSelector(state => state.depots)
  const openingTimes = useSelector(state => state.openingTimes)
  const currentDomain = useSelector(state  => state.domain)

  const [values, setValues] = useState({
    pickupTime: null,
    returnTime: null,
    pickupDate: null,
    returnDate: null,
    pickupTimeslot: null,
    returnTimeslot: null,
    pickupDepot: null,
    returnDepot: null
  })

  const [pickupOpeningTimes, setPickupOpeningTimes] = useState([])
  const [returnOpeningTimes, setReturnOpeningTimes] = useState([])
  const [pickupTimeslots, setPickupTimeslots] = useState([])
  const [returnTimeslots, setReturnTimeslots] = useState([])

  // Only depots for current domain
  const pickupDepots = depots.filter(depot => depot.domain_id === currentDomain.id)

  // All depots with current domain depots in front
  const otherDepots = depots.filter(depot => depot.domain_id !== currentDomain.id)
  const returnDepots = pickupDepots.concat(otherDepots)

  const handleReset = () => {
    const values = {
      pickupTime: cart.pickup_time && moment(cart.pickup_time),
      pickupDate: cart.pickup_time && moment(cart.pickup_time).toDate(),
      pickupDepot: depots.find((depot) => depot.id === cart.pickup_depot_id),
      returnTime: cart.return_time && moment(cart.return_time),
      returnDate: cart.return_time && moment(cart.return_time).toDate(),
      returnDepot: depots.find((depot) => depot.id === cart.return_depot_id)
    }

    if (values.pickupTime && values.returnTime) {
      values.pickupTimeslot = getTimeslot(values.pickupTime, timeslots, openingTimes, values.pickupDepot.id, 'pickup')
      values.returnTimeslot = getTimeslot(values.returnTime, timeslots, openingTimes, values.returnDepot.id, 'return')
    }

    setValues(prevValues => {
      return {
        ...prevValues,
        ...values
      }
    })
  }

  // Fetch opening times and timeslots data on component mount
  useEffect(() => {
    if (openingTimes.length === 0) {
      dispatch(fetchOpeningTimes())
    }
    if (timeslots.length === 0) {
      dispatch(fetchTimeslots())
    }
    if (depots.length === 0) {
      dispatch(fetchDepots())
    }
  }, [])

  // Reflect cart changes
  useEffect(() => {
    handleReset()
  }, [cart.pickup_time, cart.return_time, cart.pickup_depot_id, cart.return_depot_id])

  useEffect(() => {
    const { pickupDepot, pickupDate, pickupTimeslot, returnDepot } = values

    if (pickupDepot) {
      const timeRules = openingTimes.filter((el) => el.depot_id === pickupDepot.id)

      if (!isEqual(timeRules, pickupOpeningTimes)) {
        setPickupOpeningTimes(timeRules)
      }

      // Set the same return depot if it's value is empty
      if (!returnDepot) {
        setValues((prevValues) => {
          return {
            ...prevValues,
            pickupTime: fromAsTime(pickupDate, pickupTimeslot),
            returnDepot: pickupDepot
          }
        })
      } else {
        // Set/Update pickup time when pickup depot changes
        setValues((prevValues) => {
          return {
            ...prevValues,
            pickupTime: fromAsTime(pickupDate, pickupTimeslot)
          }
        })
      }
    }
  }, [values.pickupDepot, openingTimes])

  useEffect(() => {
    const { returnDepot, returnDate, returnTimeslot } = values

    if (returnDepot) {
      const timeRules = openingTimes.filter((el) => el.depot_id === returnDepot.id)

      if (!isEqual(timeRules, returnOpeningTimes)) {
        setReturnOpeningTimes(timeRules)
      }

      // Set/Update return time when return depot changes
      setValues((prevValues) => {
        return {
          ...prevValues,
          returnTime: tillAsTime(returnDate, returnTimeslot)
        }
      })
    }
  }, [values.returnDepot, openingTimes])

  useEffect(() => {
    const { pickupTimeslot } = values

    if (pickupTimeslot && pickupTimeslots) {
      const timeslot = pickupTimeslots.find((timeslot) => timeslot.from_time_integer === pickupTimeslot.from_time_integer)

      setValues((prevValues) => {
        return {
          ...prevValues,
          pickupTimeslot: timeslot ? timeslot : null
        }
      })
    }
  }, [pickupTimeslots])

  useEffect(() => {
    const { returnTimeslot } = values

    if (returnTimeslot && returnTimeslots) {
      const timeslot = returnTimeslots.find((timeslot) => timeslot.from_time_integer === returnTimeslot.from_time_integer)

      setValues((prevValues) => {
        return {
          ...prevValues,
          returnTimeslot: timeslot ? timeslot : null
        }
      })
    }
  }, [returnTimeslots])

  useEffect(() => {
    const { pickupDate, pickupDepot, pickupTimeslot } = values

    if (pickupDate) {
      setValues((prevValues) => {
        return {
          ...prevValues,
          pickupTime: fromAsTime(pickupDate, pickupTimeslot)
        }
      })

      if (pickupOpeningTimes.length > 0) {
        const newTimeslots = filterTimeslots(pickupDate, timeslots, pickupOpeningTimes, pickupDepot.id)

        if (!isEqual(newTimeslots, pickupTimeslots) && newTimeslots.length > 0) {
          setPickupTimeslots(newTimeslots)
        }
      }
    }
  }, [values.pickupDate, values.pickupDepot, pickupOpeningTimes, timeslots])

  useEffect(() => {
    const { returnDate, returnDepot, returnTimeslot } = values

    if (returnDate) {
      setValues((prevValues) => {
        return {
          ...prevValues,
          returnTime: tillAsTime(returnDate, returnTimeslot)
        }
      })

      if (returnOpeningTimes.length > 0) {
        const newTimeslots = filterTimeslots(returnDate, timeslots, returnOpeningTimes, returnDepot.id)

        if (!isEqual(newTimeslots, returnTimeslots) && newTimeslots.length > 0) {
          setReturnTimeslots(newTimeslots)
        }
      }
    }
  }, [values.returnDate, values.returnDepot, returnOpeningTimes, timeslots])

  useEffect(() => {
    const { pickupDate, pickupTimeslot } = values

    if (pickupTimeslot) {
      setValues((prevValues) => {
        return {
          ...prevValues,
          pickupTime: fromAsTime(pickupDate, pickupTimeslot)
        }
      })
    }
  }, [values.pickupTimeslot])

  useEffect(() => {
    const { returnDate, returnTimeslot } = values

    if (returnTimeslot) {
      setValues((prevValues) => {
        return {
          ...prevValues,
          returnTime: tillAsTime(returnDate, returnTimeslot)
        }
      })
    }
  }, [values.returnTimeslot])

  const handleSave = () => {
    dispatch(updateCart({
      pickup_time: values.pickupTime,
      return_time: values.returnTime,
      pickup_depot_id: values.pickupDepot.id,
      return_depot_id: values.returnDepot.id
    }))
  }

  const handleChange = (key, value) => {
    setValues(prevValues => {
      return {
        ...prevValues,
        [key]: value
      }
    })
  }

  const hasError = (errors, type, data) => {
    if (errors.length) {
      return errors.find((error) => error.error.data === data && error.error.type === type) !== undefined
    } else {
      return false
    }
  }

  const validation = new Validation({ openingTimes, ...values })

  return (
    <ModalWrapper values={values} intro={intro} errors={validation.errors}>
      <LocationDate
        returnDepots={returnDepots}
        pickupDepots={pickupDepots}
        values={values}
        pickupOpeningTimes={pickupOpeningTimes}
        returnOpeningTimes={returnOpeningTimes}
        pickupTimeslots={pickupTimeslots}
        returnTimeslots={returnTimeslots}
        handleSave={handleSave}
        isDayAvailable={validation.isDayAvailable.bind(validation)}
        isTimeslotAvailable={validation.isTimeslotAvailable.bind(validation)}
        firstAvailableAt={validation.firstAvailableAt}
        onChange={handleChange}
        handleReset={handleReset}
        inFlow={!cart.pickup_time}
        range={{ from: values.pickupDate, to: values.returnDate }}
        errors={validation.errors}
        hasError={hasError}
        {...props}
      />
    </ModalWrapper>
  )
}

export default Picker
