import React from 'react'

// Utils
import { times } from 'lodash'

const List = ({ accessories, isSelected, onChange }) => {
  const renderList = (accessories) => {
    return accessories.flatMap(accessory => {
      return times(accessory.qty, (i) => {
        const id = `${accessory.id}::${i}`

        return (
          <ListItem
            key={id}
            id={id}
            name={accessory.name}
            image={accessory.image_thumb}
            selected={isSelected(id)}
            onChange={onChange}
          />
        )
      })
    })
  }

  return (
    <div className="vstack gap-2">
      {renderList(accessories)}
    </div>
  )
}

const ListItem = ({ id, name, image, onChange, selected }) => {
  const modifiers = []

  if (selected) { modifiers.push('selected') }

  const handleClick = (e) => {
    onChange(id, e.target.checked)
  }

  return (
    <label className={`btn accessory-item ${modifiers.map(m => `accessory-item--${m}`)}`}>
      <img src={image} className="img-fluid" loading="lazy" />
      <span>{name}</span>
      <div className="ml-auto">
        <input type="checkbox" name="accessory" onChange={handleClick} checked={selected} />
        {selected && <i className="fas fa-check" />}
        {!selected && <i className="fas fa-plus" />}
      </div>
    </label>
  )
}

export default List
