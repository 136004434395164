import React from 'react'

// Components
import Icon from '@client/react/components/AddProductToCart/Icon'

const Button = ({ label, className, onClick, disabled, loading, success }) => {
  if (loading || success) {
    return (
      <button className={className} disabled>
        <Icon loading={loading} success={success} /> {label}
      </button>
    )
  } else {
    return (
      <button className={className} onClick={onClick} disabled={disabled}>{label}</button>
    )
  }
}

export default Button
