// React
import React from 'react'

// Components

// Libraries

// Shared

const Details = ({ onBackPress, title, children }) => {
  return (
    <div className="bc-mobile-picker-details">
      <div className="bc-mobile-picker-details__header" onClick={onBackPress}>
        <i className="fas fa-chevron-left bc-mobile-picker-details__back-icon"></i>
        <h3 className="bc-mobile-picker-details__title">{title}</h3>
      </div>
      {children}
    </div>
  )
}

export default Details
