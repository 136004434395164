import { useEffect, useRef } from 'react'

// Utils
import isMobile from '@client/utils/isMobile'

export default (callback) => {
  const node = useRef()

  const handleClickOutside = (e) => {
    if (!node.current || !node.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener(isMobile() ? 'touchstart' : 'mousedown', handleClickOutside)

    return () => {
      document.removeEventListener(isMobile() ? 'touchstart' : 'mousedown', handleClickOutside)
    }
  }, [node.current, callback])

  return node
}
