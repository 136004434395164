import { fetchAll } from '@client/api/timeslots'

const start = () => {
  return {
    type: 'FETCH_TIMESLOTS_START'
  }
}

const success = ({ timeslots }) => {
  return {
    type: 'FETCH_TIMESLOTS_SUCCESS',
    payload: { timeslots }
  }
}

const error = ({ response }) => {
  return {
    type: 'FETCH_TIMESLOTS_ERROR',
    payload: { response }
  }
}

const fetchTimeslots = () => {
  return (dispatch) => {
    dispatch(start())

    return fetchAll()
      .then(
        (response) => { dispatch(success({ timeslots: response.data.timeslots })) },
        (errorData) => { dispatch(error({ response: errorData.response })) }
      )
  }
}

export default fetchTimeslots
