import axios from '@client/lib/axios'

export const fetch = ({ token }) => {
  return axios({
    method: 'get',
    url: '/api/1/cart',
    params: { token }
  })
}

export const update = (params) => {
  const { token, ...cartParams } = params

  return axios({
    method: 'put',
    url: '/api/1/cart',
    data: {
      token,
      cart: cartParams
    }
  })
}

export const book = ({ token, id, type, quantity = 1, accessoryIds }) => {
  return axios({
    method: 'post',
    url: '/api/1/cart/book',
    params: {
      token,
      type,
      quantity,
      product_id: id,
      accessory_ids: accessoryIds
    }
  })
}

export const clear = () => {
  return axios({
    method: 'post',
    url: '/api/1/cart/clear'
  })
}

export const applyCoupon = ({ coupon, customerId }) => {
  return axios({
    method: 'post',
    url: '/api/1/cart/apply_coupon',
    data: {
      coupon,
      customer_id: customerId
    }
  })
}

export const removeCoupon = ({ coupon, customerId }) => {
  return axios({
    method: 'post',
    url: '/api/1/cart/remove_coupon'
  })
}
