import React from 'react'

// Utils
import { productsPath } from '@client/utils'
import { t } from '@client/i18n/localize'

// Components
import FilterGroup from './FilterGroup'

const List = ({ selectedCount, filterGroups, selectedFilters, handleToggleFilter, counters, navigate }) => {
  return (
    <div className="vstack gap-3">
      <div className="d-flex align-items-end gap-3 justify-content-md-between">
        <span className="text-large text-medium">
          {t('product_results.filters')} <span className="text-small text-gray">({selectedCount})</span>
        </span>
        <a href={productsPath()} className="">
          {t('product_results.reset_filters')}
        </a>
      </div>
      {filterGroups.map((filterGroup) => (
        <FilterGroup
          key={filterGroup.id}
          {...filterGroup}
          selectedFilters={selectedFilters}
          handleToggleFilter={handleToggleFilter}
          counters={counters}
          navigate={navigate}
        />
      ))}
    </div>
  )
}

export default List
