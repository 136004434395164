import axios from '@client/lib/axios'

export const fetchAll = (params={}) => {
  return axios({
    method: 'get',
    url: '/api/1/wishlists',
    params: params
  })
}

export const fetch = ({ id }) => {
  return axios({
    method: 'get',
    url: `/api/1/wishlists/${id}`
  })
}

export const create = ({ name, productIds }) => {
  return axios({
    method: 'post',
    url: '/api/1/wishlists',
    data: { wishlist: { name, product_ids: productIds } }
  })
}

export const add = ({ id, productId }) => {
  return axios({
    method: 'post',
    url: `/api/1/wishlists/${id}/add`,
    params: { product_id: productId }
  })
}

export const remove = ({ id, productId }) => {
  return axios({
    method: 'post',
    url: `/api/1/wishlists/${id}/remove`,
    params: { product_id: productId }
  })
}
