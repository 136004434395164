import moment from '@client/i18n/moment'
import { sortBy } from 'lodash'

const filterTimeslots = (time, timeslots, openingTimes, depotId) => {
  const weekday = moment(time).day()
  const openingTime = openingTimes.find((el) => el.weekday === weekday && el.depot_id === depotId)

  if (openingTime) {
    const filterdTimeslots = timeslots.filter((el) => el.opening_time_id === openingTime.id)

    return sortBy(filterdTimeslots, 'from_time_integer')
  }

  return []
}

const getTimeslot = (time, timeslots, openingTimes, depotId, type) => {
  time = moment(time)

  const timeInteger = (time.hour() * 100) + time.minute()

  return filterTimeslots(time, timeslots, openingTimes, depotId).find((timeslot) => {
    if (type === 'pickup') {
      return timeInteger >= timeslot.from_time_integer && timeInteger < timeslot.till_time_integer
    } else {
      return timeInteger > timeslot.from_time_integer && timeInteger <= timeslot.till_time_integer
    }
  })
}

const asTime = (datetime, timeString) => {
  const [hourString, minuteString] = timeString.split(':')
  return moment(datetime).hour(parseInt(hourString)).minute(parseInt(minuteString))
}

const fromAsTime = (datetime, timeslot) => {
  if (datetime && timeslot) {
    return asTime(datetime, timeslot.from_time)
  } else {
    return moment(datetime)
  }
}

const tillAsTime = (datetime, timeslot) => {
  if (datetime && timeslot) {
    return asTime(datetime, timeslot.till_time)
  } else {
    return moment(datetime)
  }
}

export { getTimeslot, filterTimeslots, asTime, fromAsTime, tillAsTime }
