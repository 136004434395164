import React, { forwardRef } from 'react'

const Icon = forwardRef(({ onClick, loading, success }, ref) => {
  if (success) {
    return <i ref={ref} className="fas fa-check " />
  } else if (loading) {
    return <i ref={ref} className="fas fa-spinner-third fa-spin" />
  } else {
    return <i ref={ref} className="fas fa-shopping-cart add-to-cart__icon add-to-cart animated animated-zoom" onClick={onClick} role="button" />
  }
})

export default Icon
