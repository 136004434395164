import React, { useState } from 'react'

// Libraries
import { useSelector } from 'react-redux'

// Utils
import { t, translatePeriodLabel } from '@client/i18n/localize'
import useClickOutside from '@client/react/hooks/useClickOutside'
import { productPath, productsPath } from '@client/utils'

const Product = ({ name, image, slug, quantity, priceLabel, formattedTotal }) => {
  return (
    <div className="d-flex gap-3 text-small border-bottom py-3">
      <img alt={name} src={image} className="img-fluid" width="48" />
      <div className="col">
        <a href={productPath(slug)} className="link-dark d-block mb-1">{name}</a>
        {quantity > 1 && <span className="text-gray">{t('shopping_cart.amount')} {quantity}</span>}
      </div>
      <div className="text-right col-auto">
        <div className="text-medium">{formattedTotal}</div>
        <div className="text-gray">{translatePeriodLabel(priceLabel)}</div>
      </div>
    </div>
  )
}

const Dropdown = ({ cartPath, cart }) => {
  return (
    <div className="dropdown__menu">
      {cart.count === 0 && <Empty />}
      {cart.count > 0 && <List cartPath={cartPath} cart={cart} />}
    </div>
  )
}

const Empty = () => {
  return (
    <>
      <div className="mb-3">
        <h4>{t('shopping_cart.empty.title')}</h4>
      </div>
      <p>{t('shopping_cart.empty.body')}</p>
      <a href={productsPath()} className="btn btn-primary">{t('shopping_cart.empty.button')}</a>
    </>
  )
}

const List = ({ cartPath, cart }) => {
  const mainProducts = cart.carts_products.filter(item => item.parent_id === null)

  return (
    <>
      <div className="h4">
        <a href={cartPath} className="link-dark">
          {t('shopping_cart.title')}
        </a>
      </div>
      <div className="dropdown__menu-list">
        {mainProducts.map((cartProduct) => (
          <Product
            key={cartProduct.id}
            name={cartProduct.product.name}
            image={cartProduct.product.imageThumb}
            slug={cartProduct.product.slug}
            quantity={cartProduct.qty}
            priceLabel={cartProduct.charge_label}
            formattedTotal={cartProduct.formattedTotal}
          />
        ))}
      </div>
      <div className="d-flex align-items-baseline justify-content-end gap-5 py-3">
        <div className="text-gray text-small text-medium">{t('prices.subtotal')}</div>
        <div className="text-right">
          <div className="text-medium text-large">{cart.formattedSubtotal}</div>
          <div className="text-small">({t('prices.excluding_tax')})</div>
        </div>
      </div>
      <div className="hstack gap-2 justify-content-end">
        <a className="btn btn-link" href={cartPath}>{t('shopping_cart.edit')}</a>
        <a className="btn btn-primary" href={cartPath}>{t('shopping_cart.reserve')}</a>
      </div>
    </>
  )
}

const CartIcon = ({ cartPath, dropdown }) => {
  const cart = useSelector(state => state.cart)

  const [collapsed, setCollapsed] = useState(true)
  const node = useClickOutside(() => { setCollapsed(true) })

  const handleToggle = (e) => {
    e && e.preventDefault()
    setCollapsed(!collapsed)
  }

  const classNames = ['dropdown shopping-cart']
  classNames.push(collapsed ? 'dropdown--collapsed' : 'dropdown--expanded')

  const cartIcon = cart.count > 8 ? 'fa-truck-field' : 'fa-shopping-cart'
  const dropdownIcon = collapsed ? 'fa-chevron-down' : 'fa-chevron-up'
  const cartCount = cart.count > 99 ? '99+' : cart.count

  return (
    <div className={classNames.join(' ')} ref={node}>
      <a className="hstack gap-2 dropdown__toggle p-0 px-md-3 py-md-2" href={cartPath} onClick={dropdown !== false ? handleToggle : null}>
        <i className={`fas ${cartIcon}`}>
          {cart.count > 0 && <span className="dropdown__toggle__badge">{cartCount}</span>}
        </i>
        <span className="d-none d-lg-block">{t('cart')}</span>
        {dropdown !== false && <i className={`fas ${dropdownIcon} text-small`} onClick={handleToggle} />}
      </a>

      {!collapsed && <Dropdown cartPath={cartPath} cart={cart} />}
    </div>
  )
}

export default CartIcon
