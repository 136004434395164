import React, { useEffect } from 'react'

// Libraries
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { fetchWishlists, addToWishlist, removeFromWishlist } from '@client/redux/actions/wishlists'

// Utils
import { t } from '@client/i18n/localize'

// Components
import Modal from '@client/react/components/Modal'

const ListItem = ({ id, name, selected, onChange }) => {
  const handleClick = (e) => {
    e.preventDefault()

    onChange(id, e.target.checked)
  }

  const modifiers = []

  if (selected) { modifiers.push('selected') }

  const modifierClass = modifiers.map(m => `accessory-item--${m}`).join(' ')
  const iconClass = selected ? 'fas fa-check text-primary' : 'fas fa-plus'

  return (
    <label className={`btn accessory-item ${modifierClass}`}>
      <input type="checkbox" name="favourite-item" className="d-none" onChange={handleClick} checked={selected} />
      <span className="col">{name}</span>
      <i className={iconClass} />
    </label>
  )
}

const List = ({ wishlists, product, onChange }) => {
  const isSelected = (wishlist) => {
    return wishlist.product_ids.indexOf(product.id) >= 0
  }

  if (wishlists.length === 0) {
    return (
      <p>{t('wishlists.no_lists_yet')}</p>
    )
  } else {
    return (
      <div className="vstack gap-2">
        {wishlists.map((wishlist) => (
          <ListItem
            key={wishlist.id}
            id={wishlist.id}
            name={wishlist.name}
            selected={isSelected(wishlist)}
            onChange={onChange}
          />
        ))}
      </div>
    )
  }
}

const SelectList = ({ product, onNewList }) => {
  const dispatch = useDispatch()
  const wishlists = useSelector(state => state.wishlists)

  useEffect(() => {
    dispatch(fetchWishlists())
  }, [])

  const handleToggle = (wishlistId, checked) => {
    if (checked) {
      dispatch(addToWishlist({ id: wishlistId, productId: product.id }))
    } else {
      dispatch(removeFromWishlist({ id: wishlistId, productId: product.id }))
    }
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{t('wishlists.choose_list')}</Modal.Title>
        <Modal.SubTitle>{product.name}</Modal.SubTitle>
      </Modal.Header>
      <Modal.Body>
        <div className="hstack gap-3">
          <div className="d-none d-sm-block col-auto">
            <img src={product.image} alt="product-image" className="img-fluid" width="125px" loading="lazy" />
          </div>
          <div className="col">
            <List wishlists={wishlists} product={product} onChange={handleToggle} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={onNewList}>
          {t('wishlists.create_new_list')}
        </button>
      </Modal.Footer>
    </>
  )
}

export default SelectList
