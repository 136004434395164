import React from 'react'

// Utils
import { productPath } from '@client/utils'

// Components
import Tile from './Tile'
import ListItem from './ListItem'

const Product = (props) => {
  const { type, slug, ...otherProps } = props
  const path = productPath(slug)

  const components = {
    tile: Tile,
    list: ListItem
  }
  const Component = components[type] || Tile

  return <Component {...otherProps} path={path} />
}

export default Product
