const fakeTracker = (...args) => {
  console.log('gtm', ...args);
};

export const tracker = () => {
  if (!window.dataLayer) {
    return fakeTracker;
  }

  window.dataLayer = window.dataLayer || [];
  return (event) => window.dataLayer.push(event);
};

const listSources = {
  essentials: 'Essential list',
  quicksearch: 'Quicksearch',
};

const splitAndTrimCategories = (categories) => (
  categories ? categories.split('|').map(category => category.trim()) : []
);

const productLabel = (label) => (
  label?.trim() || undefined
);

const createProductObject = (product, source) => {
  const { name, id, categories_nl, categories_translated, label } = product;

  const categoriesNL = splitAndTrimCategories(categories_nl);
  const categoriesTranslated = splitAndTrimCategories(categories_translated);

  return {
    item_name: name,
    item_id: id,
    item_list_name: productLabel(label) || listSources[source] || source,
    item_category_nl: categoriesNL[0] || '',
    item_category2_nl: categoriesNL[1] || '',
    item_category3_nl: categoriesNL[2] || '',
    item_category_translated: categoriesTranslated[0] || '',
    item_category2_translated: categoriesTranslated[1] || '',
    item_category3_translated: categoriesTranslated[2] || '',
  };
};

const track = (event, data) => {
  const gtm = tracker();

  const handleProductImpression = () => {
    const items = data.products.map((product) =>
      createProductObject(product, data.source)
    );

    gtm({
      event: 'view_item_list',
      ecommerce: { items },
    });
  };

  const handleProductClickOrView = (eventType) => {
    gtm({
      event: eventType,
      ecommerce: {
        items: [
          createProductObject(data.product, data.source),
        ],
      },
    });
  };

  const handleCartBookProduct = () => {
    const line = data.cart.carts_products.find(
      (line) => line.product_id === data.productId
    );

    gtm({
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            ...createProductObject(line.product, data.source),
            quantity: 1,
          },
        ],
      },
    });
  };

  const handleCartChangeProductQuantity = () => {
    const quantityDiff = data.quantity - data.newQuantity;
    const eventType = quantityDiff > 0 ? 'add_to_cart' : 'remove_from_cart';

    gtm({
      event: eventType,
      ecommerce: {
        items: [
          {
            ...createProductObject(data.product, data.source),
            quantity: quantityDiff,
          },
        ],
      },
    });
  };

  const handleCheckoutSteps = () => {
    const checkoutSteps = {
      'checkout.cart': 1,
      'checkout.sign_in': 2,
      'checkout.information': 3,
    };

    const items = data.products.map((product) =>
      createProductObject(product, data.source)
    );

    if (event === 'checkout.cart') {
      gtm({
        event: 'begin_checkout',
        ecommerce: { items },
      });
    }

    gtm({
      event: 'set_checkout_option',
      checkout_step: checkoutSteps[event],
      checkout_option: event.split('.')[1],
    });
  };

  const handleCheckoutConfirmation = () => {
    const items = data.products.map((product) =>
      createProductObject(product, data.source)
    );

    gtm({
      event: 'purchase',
      ecommerce: {
        transaction_id: data.id,
        value: parseFloat(data.total),
        currency: 'EUR',
        items,
      },
    });
  };

  switch (event) {
    case 'product.impression':
      handleProductImpression();
      break;
    case 'product.click':
      handleProductClickOrView('select_item');
      break;
    case 'product.view':
      handleProductClickOrView('view_item');
      break;
    case 'cart.book_product':
      handleCartBookProduct();
      break;
    case 'cart.change_product_quantity':
      handleCartChangeProductQuantity();
      break;
    case 'checkout.cart':
    case 'checkout.sign_in':
    case 'checkout.information':
      handleCheckoutSteps();
      break;
    case 'checkout.confirmation':
      handleCheckoutConfirmation();
      break;
    default:
      console.warn(`Unhandled event type: ${event}`);
  }
};

export default track;
