// React
import React from 'react'

// Components

// Libraries
import { t } from '@client/i18n/localize'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'

// Shared
const groupDepots = (depots) => {
  const grouped = groupBy(depots, (depot) => depot.domain.country_name)
  return map(grouped, (v, k) => ({ name: k, depots: v }))
}

const Depots = ({ depots, type, onChange }) => {
  const groupedDepots = groupDepots(depots)

  const storeType = (type) => {
    if (type != 'normal') {
      return (<small>({t(`depot.store_types.${type}`)})</small>)
    }
  }

  const renderDepotGroup = (group) => (
    <div key={group.name}>
      <p className="bc-mobile-picker-depot__group">{group.name}</p>
      <ul>
        {group.depots.map(depot => renderDepot(depot))}
      </ul>
    </div>
  )

  const renderDepot = (depot) => (
    <li onClick={() => onChange(depot)} className="bc-mobile-picker-depot" key={depot.id}>
      <p className="bc-mobile-picker-depot__label">{depot.name} {storeType(depot.store_type)}</p>
      <p className="bc-mobile-picker-depot__details">{depot.address}</p>
    </li>
  )

  return (
    <div className="bc-mobile-picker-depots">
      {groupedDepots.map(group => renderDepotGroup(group))}
    </div>
  )
}

export default Depots
