export default {
  nl: {
    formats: {
      date: {
        date: 'DD-MM-YYYY',
        time: 'HH:mm',
        default: 'DD-MM-YYYY HH:mm'
      }
    }
  }
}
